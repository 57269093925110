/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  createNotifcation,
  getAllNotification,
} from "../../../../../store/send-notification/send-notification-action";
import { resetEditAdItem } from "../../../../../store/send-notification/send-notification-reducer";

type Props = {
  className: string;
};

export interface IsendNotificaation {
  _id?: string;
  title?: string;
  description?: string;
  createdAt?: Date;
}

const SendNotifcationForm = ({ className }: Props) => {
  const editAdItem = useAppSelector(
    (state) => state.sendNotificationReducer.itemToBeEdit
  );
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [error, setError] = useState(true);
  const [scrollTop, setScrollTop] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editAdItem) {
      setTitle(editAdItem?.item?.title || "");
      setDescription(editAdItem?.item?.description || "");
      setId(editAdItem?.item?._id || "");
      setIsEdit(editAdItem.isEdit || false);
      setScrollTop(true);
    }
  }, [editAdItem]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (title && description) {
      const obj: IsendNotificaation = {
        _id: id,
        title,
        description,
      };
      dispatch(createNotifcation(obj));
      dispatch(resetEditAdItem({ item: {}, isEdit: false }));
      dispatch(getAllNotification({ page: 1 }));
    } else {
      setError(false);
    }
    setDescription("");
    setTitle("");
  };

  const resetHandler = () => {
    setDescription("");
    setTitle("");
    dispatch(resetEditAdItem({ item: {}, isEdit: false }));
  };
  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
      setScrollTop(false);
    }
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <form
      className="form w-100"
      onSubmit={handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >
      <div style={{ backgroundColor: "#000F1E" }} className="card row mb-6">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Send Notifications</h3>
          </div>
        </div>
        <div className="col-lg px-9">
          <div className="col-lg fv-row">
            <label className=" pb-5 fs-6">Notification Title</label>
            <input
              required
              style={{ backgroundColor: "#000810", padding: "12px 10px" }}
              className="form-control form-control-lg  mb-3 mb-lg-0"
              placeholder="Add notification title..."
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setError(true);
              }}
              type="text"
              name="title"
            />
          </div>

          <div className="col-lg fv-row">
            <label className=" pb-5 py-5 fs-6">Notification Description</label>
            <textarea
              required
              style={{ backgroundColor: "#000810", height: "100px" }}
              className="form-control form-control form-control"
              placeholder="Enter notification description..."
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setError(true);
              }}
              name="description"
            />
          </div>

          <div className="col-lg-6 py-5 fv-row">
            <button
              type="submit"
              style={{ backgroundColor: "#00874F" }}
              className="btn fw-bolder px-10"
            >
              {isEdit ? "Send Notification" : " Send Notification"}
            </button>
            {isEdit && (
              <button
                type="button"
                onClick={resetHandler}
                style={{ backgroundColor: "#00874F" }}
                className="btn fw-bolder px-10 ms-3"
              >
                Reset
              </button>
            )}
            <div className="col-lg-6 py-5 fv-row">
              {!error && (
                <p className="text-danger">Make sure to fill all field</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export { SendNotifcationForm };

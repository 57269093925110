import { createAction, createReducer } from "@reduxjs/toolkit";

export const googlePopupAction = createAction(
  "google-popup",
  (data: boolean) => {
    return {
      payload: data,
    };
  }
);

export const resetGooglePopupAction = createAction(
  "reset-google-popup",
  (data: boolean) => {
    return {
      payload: data,
    };
  }
);

const initialState = {
  googlePopup: false,
};
const popupReducer = createReducer(initialState, (builder) => {
  builder.addCase(googlePopupAction, (state, action) => {
    state.googlePopup = action.payload;
  });
  builder.addCase(resetGooglePopupAction, (state, action) => {
    state.googlePopup = initialState.googlePopup;
  });
});

export default popupReducer;

import { configureStore } from "@reduxjs/toolkit";
import { addAboutReducer } from "./addabout/add-about-reducer";
import { createAdReducer } from "./advertisement/advertisement-reducer";
import { airportReducer } from "./airports/airports-reducers";
import { latlngReducer } from "./google-map-popup/googlemap-reducer";
import popupReducer from "./google-map-popup/popup";
import { ourStoryReducer } from "./ourstory/our-story-reducers";
import { petReliefAreaReducer } from "./petreliefarea/pet-relief-area-reducer";
import { sendNotificationReducer } from "./send-notification/send-notification-reducer";
import { createVideoReducer } from "./videos/add-videos-reducer";
import { usersReducer } from "./users/users-reducers";

const store = configureStore({
  reducer: {
    createAdReducer,
    airportReducer,
    popupReducer,
    latlngReducer,
    petReliefAreaReducer,
    createVideoReducer,
    addAboutReducer,
    sendNotificationReducer,
    ourStoryReducer,
    usersReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import { FiEdit } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editOurStoryItem } from "../../../store/ourstory/our-story-reducers";

// import { editAboutItem } from "../../../../store/addabout/add-about-reducer";

type Props = {
  className: string;
};

const OurStoryTable: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const ourStory = useAppSelector((state) => state.ourStoryReducer.data);
  console.log({ ourStory });

  const tempDate = ourStory?.updatedAt && new Date(ourStory?.updatedAt);

  const formattedDate = tempDate && tempDate.toISOString().split("T")[0];

  return (
    <div
      style={{ backgroundColor: "#000F1E" }}
      className="border-0 pt-5 rounded card-header col-xl"
      id="kt_profile_details_view"
    >
      <div className="card-title m-0 px-5 d-flex justify-content-between align-items-center pb-10">
        <h3 className="fw-bolder m-0 ">Our Story Preview</h3>
        <button
          className="btn btn-icon  btn-bg-light btn-sm me-1"
          onClick={() => {
            dispatch(editOurStoryItem({ ourStory, isEdit: true }));
          }}
        >
          <FiEdit color="#16B370" className="fs-3" />
        </button>
      </div>

      <div className="col-lg px-9">
        <div className="row">
          {/* <div className="col-lg-5 pb-3 fv-row">
            <span className="text-dark fw-bold t mb-1 fs-6">Description</span>
            <br />
            <span
              className="text-dark ps-5 text-gray-600  fw-bold fs-6 text-start"
              style={{ textAlign: "start" }}
            >
              {about?.description}
            </span>
          </div> */}

          <div className="col-lg-2 pb-3 fv-row">
            <span className="text-dark fw-bold t mb-1 fs-6">Image</span>
            <br />
            <img
              src={`${ourStory?.banner}`}
              alt="our story"
              style={{ height: "150px", width: "150px" }}
            />
          </div>

          <div className="col-lg-8 pb-3 fv-row">
            <span className="text-dark fw-bold t mb-1 fs-6">Description</span>
            <br />
            <div
              className="text-dark  text-gray-600  fw-bold fs-6 text-start"
              style={{ textAlign: "start", whiteSpace: "pre-line" }}
            >
              {ourStory?.description}
            </div>
          </div>
          <div className="col-lg-1 pb-3 fv-row">
            <span className="text-dark fw-bold t mb-1 fs-6">EditedAt</span>
            <br />
            <span className="text-dark fw-bold text-gray-400 d-block mb-1 fs-6">
              {formattedDate}
            </span>
          </div>
          {/* <div className="col-lg-6 py-5 fv-row">
            <span className="text-dark fw-bold t mb-1 fs-6">Video Url</span> */}
          <br />
          {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
                overflow: "hidden",
              }}
            >
              <span
                className="text-dark fw-bold text-gray-400 d-block mb-1 fs-6"
                style={{ whiteSpace: "nowrap" }}
              >
                <div style={{ maxWidth: "200px", flexShrink: 1 }}>
                  {about?.url}
                </div>
              </span>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export { OurStoryTable };

import { createAction, createReducer } from "@reduxjs/toolkit";
import { IEngageWidget10InitValues } from "../../app/modules/airports/components/settings/SettingsModel";
import {
  createVideo,
  deleteVideo,
  editMedia,
  getAllVideo,
} from "./add-videos-actions";

type loadingCondition = "idle" | "pending" | "fulfilled" | "rejected";

export type Iadvertisement = {
  allVideos: {
    data: IEngageWidget10InitValues[];
    total: number;
  };
  loading?: string;
  itemToBeEdit: {
    item: IEngageWidget10InitValues;
    isEdit?: boolean;
    currentPage?: number;
    isEditDone: boolean;
  };
  deleteLoading?: loadingCondition;
};

const intialState: Iadvertisement = {
  allVideos: { data: [], total: 0 },
  loading: "idle",
  itemToBeEdit: {
    item: {},
    isEdit: false,
    currentPage: 0,
    isEditDone: false,
  },
  deleteLoading: "idle",
};

export const editVideoItem = createAction("edit-add-item", (itemToBeEdit) => {
  return {
    payload: itemToBeEdit,
  };
});

export const resetVideoEditItem = createAction(
  "reset-edit-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const createVideoReducer = createReducer(intialState, (bulider) => {
  // create ad
  bulider.addCase(createVideo.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(createVideo.fulfilled, (state, action) => {
    if (action.payload) {
      for (let i = 0; i < action.payload.length; i++) {
        const tempDate = new Date(action.payload[i].createdAt);
        const date = tempDate.getDate();
        const month = tempDate.getMonth() + 1;
        const year = tempDate.getFullYear();
        const createdAd = date + "/" + month + "/" + year;

        state.allVideos.data = [action.payload[i], ...state.allVideos.data];
      }
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(createVideo.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // get all add
  bulider.addCase(getAllVideo.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(getAllVideo.fulfilled, (state, action) => {
    if (action.payload) {
      state.allVideos = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllVideo.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  bulider.addCase(editVideoItem, (state, action) => {
    state.itemToBeEdit = action.payload;
  });
  // reseting the EditState
  bulider.addCase(resetVideoEditItem, (state, action) => {
    state.itemToBeEdit = intialState.itemToBeEdit;
  });

  // edit item
  bulider.addCase(editMedia.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(editMedia.fulfilled, (state, action) => {
    if (action.payload) {
      for (let i = 0; i < action.payload.length; i++) {
        state.allVideos.data = [action.payload[i], ...state.allVideos.data];
      }
    }
    state.itemToBeEdit.isEditDone = true;
    state.loading = "fulfilled";
  });
  bulider.addCase(editMedia.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // delete ad
  bulider.addCase(deleteVideo.pending, (state, action) => {
    state.deleteLoading = "pending";
  });
  bulider.addCase(deleteVideo.fulfilled, (state, action) => {
    const temp = [...state.allVideos.data];
    const id = action.payload && action.payload._id;
    temp.forEach((item: any, index) => {
      if (item?._id === id) {
        temp.splice(index, 1);
      }
    });
    state.allVideos.data = temp;
    state.deleteLoading = "fulfilled";
  });
  bulider.addCase(deleteVideo.rejected, (state, action) => {
    state.deleteLoading = "rejected";
  });
});

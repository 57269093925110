import React, { FC } from "react";
import { PageTitle } from "../../../_flyingPaws/layout/core";
import { AddAboutPage } from "../../modules/addabout/AddAboutPage";

const AddAboutWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Layout Builder</PageTitle>
      <AddAboutPage />
    </>
  );
};

export default AddAboutWrapper;

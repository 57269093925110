import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

import getToken from "../../utils/getToken";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_USERS = `${API_URL}/user/all`;
const GENERATE_USER_CSV = `${API_URL}/csv/user`;

export enum SortKey {
  Name = "name",
  Email = "email",
  CreatedAt = "createdAt",
  PetName = "petName",
  PetType = "petType",
  PetBreed = "petBreed",
  PetWeight = "petWeight",
  MostFlownAirline = "mostFlownAirline",
  FrequentFlyerMembership = "frequentFlyerMembership"
}

export enum SortDir {
  Asc = "asc",
  Desc = "desc"
}

export const getAllUsers = createAsyncThunk(
  "get-all-users",
  async (data: { page: number, role?: string, sort?: SortKey, order?: string }) => {
    try {
      const token = getToken();
      if (token) {
        const config: AxiosRequestConfig = {
          url: GET_ALL_USERS,
          method: "post",
          headers: {
            Authorization: token,
          },
          data: { ...data, limit: 10 }
        };
        const res = await axios(config);
        return res.data.data || [];
      }
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);


export const generateUserCSV = createAsyncThunk(
  'generate-user-csv',
  async (_, { rejectWithValue }) => {
    try {
      const token = getToken();
      if (token) {
        const config: AxiosRequestConfig = {
          url: GENERATE_USER_CSV,
          method: 'get',
          responseType: 'blob',
          headers: {
            Authorization: token,
          },
        };
        const response = await axios(config);
        const blob = new Blob([response.data], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'users.csv';

        a.click();

        window.URL.revokeObjectURL(url);
        return response; 
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Error downloading CSV';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

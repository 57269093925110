import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import getToken from "../../utils/getToken";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const SELECT_REGION = `${API_URL}/airport/region`;
const CREATE_NEW_AIRPORT = `${API_URL}/airport/create`;
const ALL_AIRPORTS = `${API_URL}/airport`;
const DELETE_AIRPORT = `${API_URL}/airport/delete`;
const GET_INDIVIDUAL_AIRPORT = `${API_URL}/airport/`;
const EDIT_INDIVIDUAL_AIRPORT = `${API_URL}/airport/edit`;
const FREQUENTLY_SEARCHED_AIRPORTS = `${API_URL}/airport/unregistered`;
const DELETE_FREQUENTLY_SEARCHED_AIRPORTS = `${API_URL}/airport/unregistered`;


// fetching option air select Region
export const selectRegionOptions = createAsyncThunk(
  "select-region-options",
  async () => {
    const token = getToken();
    const config: AxiosRequestConfig = {
      url: SELECT_REGION,
      method: "post",
      headers: {
        Authorization: token,
      },
    };
    const res = await axios(config);
    const temp: {
      label?: string;
      value?: string;
    }[] = [];
    for (let i = 0; i < res.data.data.length; i++) {
      if (typeof res.data.data[i] === "string") {
        temp.push({
          label: res.data.data[i],
          value: res.data.data[i],
        });
      }
    }
    return temp;
  }
);
// get-individual-airport
export const getIndividualAirport = createAsyncThunk(
  "get-individual-airport",
  async (data: { id: string; isEdit: boolean }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: GET_INDIVIDUAL_AIRPORT + data.id,
        method: "post",
        headers: {
          Authorization: token,
        },
      };
      const res = await axios(config);

      const temp = { data: res.data.data, isEdit: data.isEdit };
      return temp;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

export const editIndividualAirport = createAsyncThunk(
  "edit-individual-airport",
  async (data: any) => {
    const loadingToastId = toast.loading("Please wait...");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: EDIT_INDIVIDUAL_AIRPORT,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Edited Successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// create airports
export const createNewAirport = createAsyncThunk(
  "create-new-airport",
  async (data: any) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: CREATE_NEW_AIRPORT,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Airport successfuly created");

      // return temp;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      if (message.includes("E11000")) {
        toast.error("Airports is already exist");
      } else {
        toast.error(message);
      }
    }
  }
);

// all airports
export const allAirports = createAsyncThunk(
  "all-airports",
  async (data: { search: string; page: number }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ALL_AIRPORTS,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: { ...data, limit: 10 },
      };
      const res = await axios(config);
      if (res.data.message === "No matching records found") {
        toast.warning("No matching records found");
      }
      const temp = [];
      for (let i = 0; i < res.data.data.airport.length; i++) {
        const tempDate = new Date(res.data.data.airport[i].createdAt);
        const formattedDate = tempDate && tempDate.toISOString().split("T")[0];
        temp.push({
          id: res.data.data.airport[i]._id,
          title: res.data.data.airport[i].title,
          createdAt: formattedDate,
        });
      }
      const dineshData = { data: temp, total: res.data.data.total };
      return dineshData;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// delete airport
export const deleteAirport = createAsyncThunk(
  "delete-airport",
  async (data: { id: string }) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_AIRPORT,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

/*  Frequently Searched Airports  */

export const frequentlySearchedAirports = createAsyncThunk(
  "Frequently Searched Airports ",
  async (data: { search: string; page: number }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: FREQUENTLY_SEARCHED_AIRPORTS,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: { ...data, limit: 10 },
      };
      const res = await axios(config);
      if (
        res.data.message === "UnRegistered Airport details are currently empty"
      ) {
        toast.warning("No matching records found");
      }
      console.log(res.data.data);

      const temp = [];
      for (
        let i = 0;
        i < res.data.data.unRegisteredAirportsDetails.length;
        i++
      ) {
        // const tempDate = new Date(
        //   res.data.data.unRegisteredAirportsDetails[i]?.createdAt
        // );
        // const formattedDate = tempDate && tempDate.toISOString().split("T")[0];
        // console.log({name: res.data.data.unRegisteredAirportsDetails[i].airportDetails})
        let back = {};
        if (!res.data.data.unRegisteredAirportsDetails[i].airportDetails) {
          res.data.data.unRegisteredAirportsDetails[i].airportDetails = back;
        }
        temp.push({
          id: res.data.data.unRegisteredAirportsDetails[i]._id,
          name: res.data.data.unRegisteredAirportsDetails[i].airportDetails
            .name,
          code: res.data.data.unRegisteredAirportsDetails[i].airportCode,
          count: res.data.data.unRegisteredAirportsDetails[i].count,
          dropDown: res.data.data.unRegisteredAirportsDetails[i].airportDetails,
          // createdAt: formattedDate,
        });
      }
      const dineshData = { data: temp, total: res.data.data.total };
      console.log({ dineshData });
      return dineshData;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

export const deleteFrequentlySearchedAirport = createAsyncThunk(
  "Delete-Frequently-Searched-Airport",
  async (data: { id: string }) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_FREQUENTLY_SEARCHED_AIRPORTS + `/${data.id}`,
        method: "post",
        headers: {
          Authorization: token,
        },
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

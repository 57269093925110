import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import getToken from "../../utils/getToken";

import { IsendNotificaation } from "../../_flyingPaws/partials/widgets/_new/engage/SendNotifcationForm";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const CREAT_NOTIFICATION = `${API_URL}/notification/send`;
const GET_ALL_NOTIFICATION = `${API_URL}/notification/all`;
const DELETE_NOTIFICATION = `${API_URL}/notification/delete`;

// get all notification
export const getAllNotification = createAsyncThunk(
  "get-all-notification",
  async (data: { page: number }) => {
    try {
      const token = getToken();
      let temp = [];
      if (token) {
        const config: AxiosRequestConfig = {
          url: GET_ALL_NOTIFICATION,
          method: "post",
          headers: {
            Authorization: token,
          },
          data: { ...data, limit: 5, lastCheck: "2023-01-01T10:40:34.875Z" },
        };
        const res = await axios(config);
        if (res && res.data.data.notification) {
          for (let i = 0; i < res.data.data.notification.length; i++) {
            temp.push(res.data.data.notification[i]);
          }
        }
        const dineshData = { data: temp, total: res.data.data.total };
        return dineshData;
      }
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// create notifcation
export const createNotifcation = createAsyncThunk(
  "create-notification",
  async (data: IsendNotificaation) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: CREAT_NOTIFICATION,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Notification created successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// delete notification
export const deleteNotification = createAsyncThunk(
  "delete-notification",
  async (data: { data: IsendNotificaation; currentPage: number }) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_NOTIFICATION,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: {
          id: [data.data._id],
        },
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

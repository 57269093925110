/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Pagination, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import { useEffect, useState } from "react";
import {
  deleteVideo,
  // editVideo,
  getAllVideo,
} from "../../../../store/videos/add-videos-actions";
import { FiEdit } from "react-icons/fi";
import { editVideoItem } from "../../../../store/videos/add-videos-reducer";
import DeleteDialog from "../../modals/delete-dialog/DeleteDialog";

const StyledPagination = styled(Pagination)(({ theme }) => ({
  float: "right",
  "& .MuiPaginationItem-root": {
    color: "white!important",
    backgroundColor: "#253044!important",
    borderRadius: "8px",
  },
  "& .Mui-selected": {
    backgroundColor: "#00874F!important",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "white!important",
  },
}));
type Props = {
  className: string;
};

const AddVideosTable: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const allVideos = useAppSelector(
    (state) => state.createVideoReducer.allVideos
  );

  useEffect(() => {
    dispatch(getAllVideo({ page: currentPage }));
  }, [currentPage, dispatch]);

  // delete dialog
  const [show, setShow] = useState(false);
  const [itemItemToBeDeleted, setItemToBeDeleted] = useState<{
    item: any;
    currentPage: {
      page: number;
    };
  }>({
    item: {},
    currentPage: {
      page: 0,
    },
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    if (itemItemToBeDeleted) {
      dispatch(deleteVideo(itemItemToBeDeleted.item));
      // dispatch(getAllVideo({ page: currentPage }));
    }
    handleClose();
  };
  const deletLoading = useAppSelector(
    (state) => state.createVideoReducer.deleteLoading
  );

  useEffect(() => {
    let intervalID: any; // Declare intervalID here

    if (deletLoading === "fulfilled") {
      intervalID = setTimeout(() => {
        dispatch(getAllVideo(itemItemToBeDeleted?.currentPage));
        setItemToBeDeleted({
          item: {},
          currentPage: {
            page: 0,
          },
        });
      }, 1000); // Assign value here
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [deletLoading]);
  return (
    <div style={{ backgroundColor: "#000F1E" }} className={`card ${className}`}>
      <DeleteDialog
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Media History</span>
        </h3>
        <StyledPagination
          count={Math.ceil(allVideos.total / 5)}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table
            className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
            style={{
              borderCollapse: "inherit",
              borderSpacing: "0 10px",
            }}
          >
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted  ">
                <th className="min-w-125px text-center ">
                  <span className="me-3">Title</span>
                </th>
                <th className="min-w-120px text-start">|</th>
                <th className="min-w-120px text-start">Category</th>
                <th className="min-w-120px text-start">|</th>
                <th className="min-w-120px text-start">Type</th>
                <th className="min-w-120px text-start">|</th>
                <th className="min-w-120px text-start">Created At</th>
                <th className="min-w-100px text-end"></th>
                <th className="min-w-100px text-end" style={{paddingRight: "160px"}}>Web Url</th>
                <th className="min-w-150px text-end" align="right">
                  <span style={{ marginRight: "20px" }}> Action </span>
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {allVideos.data.map((item: any, index: number) => (
                <tr
                  style={{
                    marginBottom: "50px",
                    // backgroundColor: "#000810",
                    borderRadius: "50px",
                  }}
                  key={index}
                >
                  <td
                    style={{
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      backgroundColor: "#000810",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="symbol  d-flex symbol-40px ms-2">
                      <div className=" d-flex justify-content-center text-align-center align-items-center">
                        <div className="symbol  d-flex symbol-40px ms-2">
                          <img src={`${item?.webUrl}`} className="" alt="" />
                          <div className=" d-flex justify-content-center text-align-center align-items-center">
                            <span className="text-dark ps-5 fw-bold text-hover-primary fs-6">
                              {item.title ? item.title : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                  >
                    {" "}
                    <span className="">|</span>
                  </td>

                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                    className="text-start "
                  >
                    <div className="">
                      <span className="text-dark  fw-bold text-hover-primary fs-6">
                        {item?.category}
                      </span>
                    </div>
                  </td>
                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                  >
                    {" "}
                    <span className="">|</span>
                  </td>

                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                    className="text-start "
                  >
                    <div className="">
                      <span className="text-dark  fw-bold text-hover-primary fs-6">
                        {item?.type}
                      </span>
                    </div>
                  </td>
                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                    className="text-start"
                  >
                    {" "}
                    <span className="">|</span>
                  </td>
                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                  >
                    <span className="text-dark fw-bold text-gray-400 d-block  fs-6">
                      {item?.createdAd2 !== "NaN-NaN-NaN" ? (
                        item?.createdAd2
                      ) : (
                        <span>No Date</span>
                      )}
                    </span>
                  </td>
                  <td
                    align="left"
                    style={{
                      backgroundColor: "#000810",
                    }}
                    className="text-start "
                  >
                    <div className="">
                      <span className="text-dark fw-bold text-gray-400  fs-6">
                        {item?.link}
                      </span>
                    </div>
                  </td>

                  <td
                    style={{
                      backgroundColor: "#000810",
                    }}
                    align="right"
                  >
                    <div
                      style={{
                        width: "200px",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="text-dark fw-bold text-gray-400 d-block mb-1 fs-6"
                    >
                      {/* <span
                      className="text-dark fw-bold text-gray-400 d-block mb-1 fs-6"
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      align="right"
                    > */}
                      {item?.webUrl}
                    </div>
                  </td>
                  <td
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                      backgroundColor: "#000810",
                    }}
                    align="right"
                  >
                    <div className="d-flex justify-content-end flex-shrink-0 pe-2">
                      <button
                        className="btn btn-icon  btn-bg-light btn-sm me-1"
                        onClick={() => {
                          dispatch(
                            editVideoItem({ item, isEdit: true, currentPage })
                          );
                        }}
                      >
                        <FiEdit color="#16B370" className="fs-3" />
                      </button>

                      <button
                        className="btn btn-icon  btn-bg-light btn-color-dangor btn-sm"
                        onClick={() => {
                          handleShow();
                          setItemToBeDeleted({
                            item: item,
                            currentPage: { page: currentPage },
                          });
                        }}
                      >
                        <RiDeleteBin6Line color="red" className="fs-3" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { AddVideosTable };

import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_flyingPaws/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { AdvertisementWrapper } from "../pages/advertisement/AdvertisementWrapper";
import { getCSSVariableValue } from "../../_flyingPaws/assets/ts/_utils";
import { WithChildren } from "../../_flyingPaws/helpers";
import { VideosWrapper } from "../pages/add-videos/VideosWrapper";
import AddAboutWrapper from "../pages/add-about/AddAboutWrapper";
import { SendNotifcationWrapper } from "../pages/send-notifcation/SendNotifcationWrapper";
import { OurStoryWrapper } from "../pages/our-story/OurStoryWrapper";
import { UsersWrapper } from "../pages/users/UsersWrapper";

const PrivateRoutes = () => {
  const AirportPage = lazy(() => import("../modules/airports/AirportPage"));
  const PetReliefAreasPage = lazy(
    () => import("../modules/petreliefareas/PetReliefAreasPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/advertisement" />} />
        <Route path="advertisement" element={<AdvertisementWrapper />} />
        <Route path="media" element={<VideosWrapper />} />
        <Route path="send-notification" element={<SendNotifcationWrapper />} />
        <Route path="add-about" element={<AddAboutWrapper />} />
        <Route path="our-story" element={<OurStoryWrapper />} />
        <Route
          path="/crafted/pet-relief-areas/*"
          element={
            <SuspensedView>
              <PetReliefAreasPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/airports/*"
          element={
            <SuspensedView>
              <AirportPage />
            </SuspensedView>
          }
        />
        <Route path="users" element={<UsersWrapper />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

import { IourStoryInt } from "../../app/modules/ourstory/OurStoryForm";
import getToken from "../../utils/getToken";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_OUR_STORY = `${API_URL}/our-story/`;
const EDIT_OUR_STORY = `${API_URL}/our-story/edit`;

// getall our story
export const getAllOurStory = createAsyncThunk(
  "get-all-our-story",
  async () => {
    try {
      const token = getToken();
      if (token) {
        const config: AxiosRequestConfig = {
          url: GET_ALL_OUR_STORY,
          method: "post",
          headers: {
            Authorization: token,
          },
        };
        const res = await axios(config);
        return res.data.data;
      }
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// edit our story
export const editOurStory = createAsyncThunk(
  "edit-our-story",
  async (data: { id: string; data: IourStoryInt }) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: EDIT_OUR_STORY + "/" + data.id,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data.data,
      };

      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Edited successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

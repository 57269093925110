import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { formData } from "../../app/modules/addabout/components/AddAboutForm";
import getToken from "../../utils/getToken";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_ABOUT = `${API_URL}/about`;
const EDIT_ABOUT = `${API_URL}/about/edit`;



// getall
export const getAllAbout = createAsyncThunk("get-all-about", async () => {
  try {
    const token = getToken();
    if (token) {
      const config: AxiosRequestConfig = {
        url: GET_ALL_ABOUT,
        method: "post",
        headers: {
          Authorization: token,
        },
      };
      const res = await axios(config);
      return res.data.data;
    }
  } catch (error: any) {
    const { message } = error?.response?.data;
    toast.error(message);
  }
});

// edit ad
export const editAbout = createAsyncThunk(
  "edit-about",
  async (data: formData) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: EDIT_ABOUT + "/" + data._id,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };

      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Edited successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

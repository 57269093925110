import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { getAllOurStory } from "../../../store/ourstory/our-story-actions";
import { OurStoryForm } from "../../modules/ourstory/OurStoryForm";
import { OurStoryTable } from "../../modules/ourstory/OurStoryTable";

const OurStory: FC = () => {
  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mb-xl-0 ">
        <div className=" col-xxl">
          <OurStoryForm className="h-md-100" />
        </div>
      </div>
      <div className="row gy-5 gx-xl-8">
        <div
          style={{ backgroundColor: "#000F1E" }}
          className=" border-0 pt-5 rounded card-header col-xl"
        >
          <OurStoryTable className="card-xxl-stretch mb-5 mb-xl-5" />
        </div>
      </div>
    </>
  );
};

const OurStoryWrapper = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllOurStory());
  }, [dispatch]);
  return (
    <>
      <OurStory />
    </>
  );
};

export { OurStoryWrapper };

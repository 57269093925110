/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  activateAd,
  deleteAd,
  getAllAd,
  // getAllAd,
} from "../../../../store/advertisement/advertisement-action";
import { mydata } from "../_new/engage/AdForm";
import { editAdItem } from "../../../../store/advertisement/advertisement-reducer";
import { Pagination, styled } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteDialog from "../../modals/delete-dialog/DeleteDialog";
type Props = {
  className: string;
};
const StyledPagination = styled(Pagination)(({ theme }) => ({
  float: "right",
  "& .MuiPaginationItem-root": {
    color: "white!important",
    backgroundColor: "#253044!important",
    borderRadius: "8px",
  },
  "& .Mui-selected": {
    backgroundColor: "#00874F!important",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "white!important",
  },
}));
const TablesWidget10: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [itemItemToBeDeleted, setItemToBeDeleted] = useState<{
    item?: any;
    currentPage?: any;
  }>({});

  const [currentPage, setCurrentPage] = useState(1);

  // delete dialog
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    if (itemItemToBeDeleted) {
      dispatch(deleteAd(itemItemToBeDeleted?.item));
    }
    handleClose();
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const deletLoading = useAppSelector(
    (state) => state.createAdReducer.deleteLoading
  );

  useEffect(() => {
    dispatch(getAllAd({ page: currentPage }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    let intervalID: any; // Declare intervalID here

    if (deletLoading === "fulfilled") {
      intervalID = setTimeout(() => {
        dispatch(getAllAd(itemItemToBeDeleted?.currentPage));
        setItemToBeDeleted({});
      }, 1000); // Assign value here
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [deletLoading]); // Don't forget to add dependencies

  const allAds = useAppSelector(
    (state) => state.createAdReducer.allAdvertisement
  );

  return (
    <div style={{ backgroundColor: "#000F1E" }} className={`card ${className}`}>
      {/* begin::Header */}
      <DeleteDialog
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Ad History</span>
        </h3>
        <StyledPagination
          count={Math.ceil(allAds.total / 5)}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table
            className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
            style={{
              borderCollapse: "inherit",
              borderSpacing: "0 10px",
            }}
          >
            {/* begin::Table head */}
            {/* <thead>
              <tr className="fw-bold text-muted">
                <th className="w-25px">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-13-check"
                    />
                  </div>
                </th>
                <th className="min-w-150px">Order Id</th>

                <th className="min-w-120px">Date</th>

                <th className="min-w-120px">Url</th>
                <th className="min-w-100px text-end">Actions</th>
              </tr>
            </thead> */}
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {allAds.data.map((item: any, index: number) => {
                const tempDate = item.createdAt && new Date(item?.createdAt);
                const formattedDate =
                  tempDate && tempDate.toISOString().split("T")[0];
                return (
                  <tr
                    style={{
                      marginBottom: "5px",
                      // backgroundColor: "#000810",
                      borderRadius: "50px",
                    }}
                    key={index}
                  >
                    <td
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                        backgroundColor: "#000810",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="symbol  d-flex symbol-40px ms-2">
                        <img src={`${item?.banner}`} className="" alt="" />
                        <div className=" d-flex justify-content-center text-align-center align-items-center">
                          <span className="text-dark ps-5 fw-bold text-hover-primary fs-6">
                            {item.title ? item.title : ""}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      align="left"
                      style={{
                        backgroundColor: "#000810",
                      }}
                    >
                      <span className="">|</span>
                    </td>

                    <td
                      align="left"
                      style={{
                        backgroundColor: "#000810",
                      }}
                      className="text-start "
                    >
                      <div className="">
                        <span className="text-dark fw-bold text-gray-400 d-block mb-1">
                          {formattedDate !== "NaN/NaN/NaN" ? (
                            formattedDate
                          ) : (
                            <span>No Date</span>
                          )}
                        </span>
                      </div>
                    </td>
                    <td
                      align="right"
                      style={{
                        backgroundColor: "#000810",
                      }}
                      className="text-end "
                    >
                      <div
                        style={{
                          width: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="text-dark fw-bold text-gray-400 mb-1 px-4 fs-6 "
                      >
                        <span className="text-dark fw-bold text-gray-400 mb-1 px-4 fs-6 ">
                          {item?.link}
                        </span>
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor: "#000810",
                      }}
                    >
                      |
                    </td>
                    <td
                      style={{
                        backgroundColor: "#000810",
                      }}
                      align="right"
                    >
                      <div
                        className="
              form-check form-check-custom form-check-solid form-check-success form-switch
                "
                      >
                        <label
                          className="form-check-label text-gray-700 fw-bold pe-5"
                          htmlFor="kt_builder_sidebar_minimize_desktop_enabled"
                          data-bs-toggle="tooltip"
                          data-kt-initialized="1"
                        >
                          Activate
                        </label>
                        <input
                          className="form-check-input p-0 m-0"
                          type="checkbox"
                          name="model.app.sidebar.default.minimize.desktop.enabled"
                          id="kt_builder_sidebar_minimize_desktop_enabled"
                          checked={item?.active}
                          // checked={
                          //   config.app?.sidebar?.default?.minimize?.desktop
                          //     ?.enabled
                          // }
                          onChange={() => {
                            let formData = new FormData() as mydata;
                            const active = item.active ? false : true;
                            formData.append("id", item._id);
                            formData.append("active", `${active}`);
                            dispatch(activateAd(formData));
                          }}
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor: "#000810",
                      }}
                    >
                      |
                    </td>
                    <td
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                        backgroundColor: "#000810",
                      }}
                      align="right"
                    >
                      <div className="d-flex justify-content-end flex-shrink-0 pe-2">
                        <button
                          className="btn btn-icon  btn-bg-light btn-sm me-1"
                          onClick={() => {
                            dispatch(
                              editAdItem({
                                item,
                                isEdit: true,
                                currentPage: currentPage,
                              })
                            );
                          }}
                        >
                          <FiEdit color="#16B370" className="fs-3" />
                        </button>

                        <button
                          className="btn btn-icon  btn-bg-light btn-color-dangor btn-sm"
                          onClick={() => {
                            handleShow();
                            const obj = {
                              item: item,
                              currentPage: { page: currentPage },
                            };
                            setItemToBeDeleted(obj);
                          }}
                        >
                          <RiDeleteBin6Line color="red" className="fs-3" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TablesWidget10 };

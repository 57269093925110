import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { IpetReliefArea } from "../../app/modules/petreliefareas/components/AddPetReliefArea";
import getToken from "../../utils/getToken";

const API_URL = process.env.REACT_APP_API_URL;
const CREATE_PET_RELIEF_AREA = `${API_URL}/airport/addReliefArea`;
const ALL_AIRPORTS = `${API_URL}/airport`;
const ALL_PET_RELIEF_AREA = `${API_URL}/airport/reliefArea`;
const ALL_PET_RELIEF_AREA_LIST = `${API_URL}/airport/airportList`;
const DELETE_PET_RELIEF_AREA = `${API_URL}/airport/deleteReliefArea`;
const EDIT_PET_RELIEF_AREA = `${API_URL}/airport/editReliefArea`;
const SEND_SEARCH_EDIT_PET_RELIEF_AREA = `${API_URL}/airport/reliefArea`;

// all airports
export const airportNameOption = createAsyncThunk(
  "airport-name-option",
  async (data: { search: string }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ALL_AIRPORTS,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      if (res.data.message === "No matching records found") {
        toast.warning("No matching records found");
      }
      const temp = [];
      for (let i = 0; i < res.data.data.airport.length; i++) {
        temp.push({
          label: res.data.data.airport[i].title,
          value: res.data.data.airport[i]._id,
        });
      }

      return temp;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// All airport name list
export const AllAirportNamesList = createAsyncThunk(
  "airport-names-list-option",
  async () => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ALL_PET_RELIEF_AREA_LIST,
        method: "post",
        headers: {
          Authorization: token,
        },
        // data: data,
      };
      const res = await axios(config);
      // if (res.data.message === "No matching records found") {
      //   toast.warning("No matching records found");
      // }
      const temp = [];
      for (let i = 0; i < res.data.data.length; i++) {
        temp.push({
          label: res.data.data[i].title,
          value: res.data.data[i]._id,
        });
      }
      console.log({ temp });
      return temp;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

export const SendAirportNamesSearch = createAsyncThunk(
  "airport-names-send-option",
  async (data: { search?: string; airport?: string }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: SEND_SEARCH_EDIT_PET_RELIEF_AREA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      if (res.data.message === "No matching records found") {
        toast.warning("No matching records found");
      }
      const temp = [];
      for (let i = 0; i < res.data.data.petReliefArea.length; i++) {
        const tempDate = new Date(res.data.data.petReliefArea[i].createdAt);
        const formattedDate = tempDate && tempDate.toISOString().split("T")[0];
        temp.push({
          id: res.data.data.petReliefArea[i]._id,
          title: res.data.data.petReliefArea[i].title,
          type: res.data.data.petReliefArea[i].type,
          name: res.data.data.petReliefArea[i].airportName,
          address: res.data.data.petReliefArea[i].address,
          latitude: res.data.data.petReliefArea[i].latitude,
          longitude: res.data.data.petReliefArea[i].longitude,
          email: res.data.data.petReliefArea[i].email,
          terminal: res.data.data.petReliefArea[i].terminal,
          gate: res.data.data.petReliefArea[i].gate,
          nearBy: res.data.data.petReliefArea[i].nearBy,
          description: res.data.data.petReliefArea[i].description,
          status: res.data.data.petReliefArea[i].status,
          createdAt: formattedDate,
        });
      }
      const dineshData = { data: temp, total: res.data.data.total };

      console.log({ res });
      return dineshData;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// create PET RELIEF AREA
export const createPetReliefArea = createAsyncThunk(
  "create-pet-relief-area",
  async (data: IpetReliefArea) => {
    const loadingToastId = toast.loading("Plaese wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: CREATE_PET_RELIEF_AREA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("pet relief area created successfully");
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// all pet relief area
export const allpetReliefArea = createAsyncThunk(
  "all-pet-relief-area",
  async (data: { airport: string; search: string; page: number }) => {
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ALL_PET_RELIEF_AREA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: { ...data, limit: 10 },
      };
      const res = await axios(config);
      if (res.data.message === "No matching records found") {
        toast.warning("No matching records found");
      }
      const temp = [];
      for (let i = 0; i < res.data.data.petReliefArea.length; i++) {
        const tempDate = new Date(res.data.data.petReliefArea[i].createdAt);
        const formattedDate = tempDate && tempDate.toISOString().split("T")[0];
        temp.push({
          id: res.data.data.petReliefArea[i]._id,
          title: res.data.data.petReliefArea[i].title,
          type: res.data.data.petReliefArea[i].type,
          name: res.data.data.petReliefArea[i].airportName,
          address: res.data.data.petReliefArea[i].address,
          latitude: res.data.data.petReliefArea[i].latitude,
          longitude: res.data.data.petReliefArea[i].longitude,
          email: res.data.data.petReliefArea[i].email,
          terminal: res.data.data.petReliefArea[i].terminal,
          gate: res.data.data.petReliefArea[i].gate,
          nearBy: res.data.data.petReliefArea[i].nearBy,
          description: res.data.data.petReliefArea[i].description,
          status: res.data.data.petReliefArea[i].status,
          createdAt: formattedDate,
        });
      }
      const dineshData = { data: temp, total: res.data.data.total };

      return dineshData;
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// edit pet relief area
export const editPetReliefArea = createAsyncThunk(
  "edit-pet-relief-area",
  async (data: IpetReliefArea) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: EDIT_PET_RELIEF_AREA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Edited successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// Pet Relief Area Delete
export const deletePetReliefArea = createAsyncThunk(
  "delete-pet-relief-area",
  async (data: { id: string }) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_PET_RELIEF_AREA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsSend } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import { Pagination, styled } from "@mui/material";
import { useEffect, useState } from "react";
import {
  deleteNotification,
  getAllNotification,
} from "../../../../store/send-notification/send-notification-action";
import { editNotifcationItem } from "../../../../store/send-notification/send-notification-reducer";
import DeleteDialog from "../../modals/delete-dialog/DeleteDialog";
import { IsendNotificaation } from "../_new/engage/SendNotifcationForm";
type Props = {
  className: string;
};

const StyledPagination = styled(Pagination)(({ theme }) => ({
  float: "right",
  "& .MuiPaginationItem-root": {
    color: "white!important",
    backgroundColor: "#253044!important",
    borderRadius: "8px",
  },
  "& .Mui-selected": {
    backgroundColor: "#00874F!important",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "white!important",
  },
}));

const SendNotificationTable: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    dispatch(getAllNotification({ page: currentPage }));
  }, [currentPage, dispatch]);

  const allNotification = useAppSelector(
    (state) => state.sendNotificationReducer.allNotification
  );
  const itemWhichIsDeleted = useAppSelector(
    (state) => state.sendNotificationReducer.itemToBeDelete
  );

  useEffect(() => {
    if (itemWhichIsDeleted) {
      dispatch(getAllNotification({ page: itemWhichIsDeleted.currentPage }));
    }
  }, [itemWhichIsDeleted]);

  // delete dialog
  const [show, setShow] = useState(false);
  const [itemItemToBeDeleted, setItemToBeDeleted] = useState<{
    data: any;
    currentPage: number;
  }>({
    data: {},
    currentPage: 0,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    if (itemItemToBeDeleted) {
      dispatch(deleteNotification(itemItemToBeDeleted));
      // dispatch(getAllNotification({ currentPage: currentPage }));
    }
    handleClose()
  };
  const deletLoading = useAppSelector(
    (state) => state.sendNotificationReducer.deleteLoading
  );
 console.log({itemItemToBeDeleted})
  useEffect(() => {
    let intervalID: any; // Declare intervalID here
    if (deletLoading === "fulfilled") {
      intervalID = setTimeout(() => {
        dispatch(
          getAllNotification({ page: itemItemToBeDeleted?.currentPage })
        );
        setItemToBeDeleted({
          data: {},
          currentPage: 0,
        });
      }, 1000); // Assign value here
    }
    
    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [deletLoading]);

  return (
    <div style={{ backgroundColor: "#000F1E" }} className={`card ${className}`}>
      <DeleteDialog
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Notification History
          </span>
        </h3>
        <StyledPagination
          count={Math.ceil(allNotification.total / 5)}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table
            className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
            style={{
              borderCollapse: "inherit",
              borderSpacing: "0 10px",
            }}
          >
            <tbody>
              {allNotification.data.map((item, index: number) => {
                const tempDate = item.createdAt && new Date(item?.createdAt);
                const formattedDate =
                  tempDate && tempDate.toISOString().split("T")[0];
                return (
                  <tr
                    style={{
                      marginBottom: "5px",
                      // backgroundColor: "#000810",
                      borderRadius: "50px",
                    }}
                    key={index}
                  >
                    <td
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                        backgroundColor: "#000810",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="symbol  d-flex symbol-40px ms-2">
                        <div className=" d-block text-left  text-align-left align-items-center">
                          <span className="text-dark ps-5 text-left  fw-bold text-hover-primary fs-6">
                            {item.title}
                          </span>
                          <span className="text-dark ps-5 fw-bold text-gray-400 d-block mb-1 fs-6">
                            {item.description}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td style={{ backgroundColor: "#000810" }} align="right">
                      <span className="px-4">|</span>
                    </td>
                    <td style={{ backgroundColor: "#000810" }} align="left">
                      <span className="text-dark fw-bold text-gray-400 d-block mb-1 fs-6">
                        {formattedDate ? formattedDate : <span>No Date</span>}
                      </span>
                    </td>
                    <td
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                        backgroundColor: "#000810",
                      }}
                      align="right"
                    >
                      <div className="d-flex justify-content-end flex-shrink-0 pe-2">
                        <button
                          className="btn btn-icon  btn-bg-light btn-sm me-1"
                          onClick={() => {
                            dispatch(
                              editNotifcationItem({ item, isEdit: true })
                            );
                          }}
                        >
                          <BsSend color="#16B370" className="fs-3" />
                        </button>

                        <button
                          className="btn btn-icon  btn-bg-light btn-color-dangor btn-sm"
                          onClick={() => {
                            handleShow();
                            setItemToBeDeleted({
                              data: item,
                              currentPage: currentPage,
                            });
                          }}
                        >
                          <RiDeleteBin6Line color="red" className="fs-3" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { SendNotificationTable };

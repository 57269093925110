/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { getAllAbout } from "../../../store/addabout/add-about-actions";
import { AddAboutForm } from "./components/AddAboutForm";
import { AddAboutTable } from "./components/AddAboutTable";

const AddAbout: FC = () => (
  <>
    <div className="row g-5 g-xl-5 mb-5 mb-xl-0 ">
      <div className=" col-xxl">
        <AddAboutForm className="h-md-100" />
      </div>
    </div>

    <div className="row gy-5 gx-xl-8">
      <div
        style={{ backgroundColor: "#000F1E" }}
        className=" border-0 pt-5 rounded card-header col-xl"
      >
        <AddAboutTable className="card-xxl-stretch mb-5 mb-xl-5" />
      </div>
    </div>
  </>
);

const AddAboutPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllAbout());
  }, [dispatch]);
  return (
    <>
      <AddAbout />
    </>
  );
};

export { AddAboutPage };

/* eslint-disable jsx-a11y/anchor-is-valid */
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  createAd,
  editAd,
  getAllAd,
} from "../../../../../store/advertisement/advertisement-action";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { resetEditAdItem } from "../../../../../store/advertisement/advertisement-reducer";

type Props = {
  className: string;
};

export interface mydata extends FormData {
  id?: string;
  _id?: string;
  title?: string;
  description?: string;
  link?: string;
  active?: boolean;
  banner?: File;
}

// interface IEditITem  {
//   item: IEngageWidget10InitValues;
//   isEdit?: boolean;
// }
const EngageWidget10 = ({ className }: Props) => {
  const editAdItem = useAppSelector(
    (state) => state.createAdReducer.itemToBeEdit
  );
  const [title, setTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState<File | null>(null);
  const [link, setLink] = useState("");
  const [error, setError] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [scrollTop, setScrollTop] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const validateUrl = (value: string) => {
    if (!value || value.trim() === "") {
      return false; // Empty or whitespace input is considered invalid
    }
    const urlPattern =
      /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w\-.\[\]\~\:\/\?\#\@\!\$\&\'\(\)\*\+\,\;\=\%]*)?$/i;
    return urlPattern.test(value);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editAdItem) {
      setTitle(editAdItem?.item?.title || "");
      setDescription(editAdItem?.item?.description || "");
      setLink(editAdItem?.item?.link || "");
      setId(editAdItem?.item?._id || "");
      setBanner(editAdItem?.item?.banner || null);
      setIsEdit(editAdItem.isEdit || false);
      setCurrentPage(editAdItem.currentPage || 0);
      setScrollTop(true);
    }
  }, [editAdItem]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEdit) {
      if (banner && link) {
        const formData = new FormData() as mydata;
        formData.append("id", id);
        formData.append("title", title);
        formData.append("description", description);
        banner && formData.append("banner", banner);
        formData.append("link", link);
        dispatch(editAd(formData));
        dispatch(getAllAd({ page: currentPage }));
        dispatch(resetEditAdItem({ item: {}, isEdit: false, currentPage: 0 }));

        setBanner(null);
        setDescription("");
        setLink("");
        setTitle("");
        setIsValid(true);
      } else {
        setError(false);
      }
    } else {
      if (banner && link) {
        const formData = new FormData() as mydata;
        formData.append("title", title);
        banner && formData.append("banner", banner);
        formData.append("description", description);
        formData.append("link", link);
        dispatch(createAd(formData));
        dispatch(getAllAd({ page: 1 }));

        setBanner(null);
        setDescription("");
        setLink("");
        setTitle("");
        setIsValid(true);
      } else {
        setError(false);
      }
    }
  };

  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
      setScrollTop(false);
    }
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetHandler = () => {
    setBanner(null);
    setDescription("");
    setLink("");
    setTitle("");
    setIsValid(true);
    dispatch(resetEditAdItem({ item: {}, isEdit: false }));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setError(true);
    if (file) {
      setBanner(file);
    }
  };
  return (
    <form
      className="form w-100"
      onSubmit={handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >
      <div style={{ backgroundColor: "#000F1E" }} className="card row mb-6">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Add Advertisement</h3>
          </div>
        </div>
        <div className="col-lg px-9">
          <div className="row">
            <div className="col-lg-6 fv-row">
              <label className=" pb-5 fs-6">Ad Title</label>
              <input
                style={{ backgroundColor: "#000810", padding: "12px 10px" }}
                className="form-control form-control-lg  mb-3 mb-lg-0"
                placeholder="Enter advertisement title..."
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                type="text"
                name="title"
              />
            </div>

            <div className="col-lg-6 fv-row">
              <label className=" pb-5 fs-6">Redirect Link</label>
              <input
                required
                style={{ backgroundColor: "#000810", padding: "12px 10px" }}
                className="form-control form-control-lg"
                placeholder="Enter redirect link"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                  setError(true);
                  setIsValid(
                    e.target.value === "" || validateUrl(e.target.value)
                  );
                }}
                type="text"
                name="link"
              />
              {!isValid && <p className="text-danger">Invalid url format</p>}
            </div>

            <div className="col-lg-6 fv-row">
              <label className=" pb-5 py-5 fs-6">Ad Description</label>
              <textarea
                style={{ backgroundColor: "#000810" }}
                className="form-control form-control pt-2 pb-13 form-control-solid"
                placeholder="Enter advertisement description..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
              />
            </div>
            <div className="col-lg-6 py-5 fv-row">
              <label className="pb-5 fs-6">Select Image</label>
              <div
                style={{ backgroundColor: "#000810" }}
                className={`border border-gray-300 text-center border-dashed rounded min-w-125px py-3 px-4 mb-3 d-flex flex-column ${
                  banner ? "" : "align-items-center"
                }`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {!banner && (
                  <label style={{ cursor: "pointer" }}>
                    <>
                      <div>
                        <AiOutlineCloudUpload
                          size={50}
                          className="fs-50 text-gray-400 me-2"
                        />
                        <div className="fw-bold fs-6 text-gray-400">
                          Browse image or drag and drop here
                        </div>
                      </div>
                      <input
                        hidden
                        type="file"
                        onChange={(e) => {
                          const file = e?.target?.files?.[0];
                          setError(true);
                          if (file) {
                            setBanner(file);
                          }
                        }}
                        accept="image/png, image/gif, image/jpeg"
                        name="banner"
                      />
                    </>
                  </label>
                )}
                <div
                  className="d-flex align-items-start justify-content-center position-relative"
                  draggable={!banner}
                  onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
                    e.dataTransfer.setData("text/plain", "dummy");
                  }}
                  onDragEnd={() => {
                    setBanner(null);
                  }}
                >
                  {banner && (
                    <>
                      <img
                        height={70}
                        width={90}
                        src={
                          typeof banner === "string"
                            ? `${banner}`
                            : URL.createObjectURL(banner)
                        }
                        alt="Selected"
                      />
                      <IoIosCloseCircleOutline
                        size={22}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBanner(null);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-5 fv-row">
            <button
              type="submit"
              style={{ backgroundColor: "#00874F" }}
              className="btn fw-bolder"
            >
              {isEdit ? "Update Ad" : " Upload Ad"}
            </button>
            {isEdit && (
              <button
                type="button"
                onClick={resetHandler}
                style={{ backgroundColor: "#00874F" }}
                className="btn fw-bolder ms-3"
              >
                Reset
              </button>
            )}
            <div className="col-lg-6 py-5 fv-row">
              {!error && (
                <span className="text-danger">Please select image</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export { EngageWidget10 };

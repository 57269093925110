import { createAction, createReducer } from "@reduxjs/toolkit";
import { IEngageWidget10InitValues } from "../../app/modules/airports/components/settings/SettingsModel";
import {
  activateAd,
  createAd,
  deleteAd,
  editAd,
  getAllAd,
} from "./advertisement-action";
type loadingCondition = "idle" | "pending" | "fulfilled" | "rejected";

export type Iadvertisement = {
  allAdvertisement: {
    data: IEngageWidget10InitValues[];
    total: number;
  };
  loading?: string;
  itemToBeEdit: {
    item: IEngageWidget10InitValues;
    isEdit?: boolean;
    currentPage?: number;
  };
  deleteLoading?: loadingCondition;
};

const intialState: Iadvertisement = {
  allAdvertisement: {
    data: [],
    total: 0,
  },
  loading: "idle",
  itemToBeEdit: {
    item: {},
    isEdit: false,
    currentPage: 0,
  },
  deleteLoading: "idle",
};

export const editAdItem = createAction("edit-ad-item", (itemToBeEdit) => {
  return {
    payload: itemToBeEdit,
  };
});

export const resetEditAdItem = createAction(
  "reset-edit-ad-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const createAdReducer = createReducer(intialState, (bulider) => {
  // create ad
  bulider.addCase(createAd.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(createAd.fulfilled, (state, action) => {
    state.allAdvertisement.data = [
      action.payload,
      ...state.allAdvertisement.data,
    ];
    state.loading = "fulfilled";
  });
  bulider.addCase(createAd.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // get all add
  bulider.addCase(getAllAd.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(getAllAd.fulfilled, (state, action) => {
    if (action.payload) {
      state.allAdvertisement = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllAd.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // activate ad
  bulider.addCase(activateAd.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(activateAd.fulfilled, (state, action) => {
    const temp = [...state.allAdvertisement.data];
    const id = action.payload._id;
    temp.forEach((item: any, index) => {
      if (item?._id === id) {
        temp[index].active = action.payload.active;
      }
    });
    state.allAdvertisement.data = temp;
    state.loading = "fulfilled";
  });
  bulider.addCase(activateAd.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  bulider.addCase(editAdItem, (state, action) => {
    state.itemToBeEdit = action.payload;
  });
  // reseting the EditState
  bulider.addCase(resetEditAdItem, (state, action) => {
    state.itemToBeEdit = intialState.itemToBeEdit;
  });

  // edit item
  bulider.addCase(editAd.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(editAd.fulfilled, (state, action) => {
    const temp = [...state.allAdvertisement.data];
    const id = action.payload._id;
    temp.forEach((item: any, index) => {
      if (item?._id === id) {
        temp[index] = action.payload;
      }
    });
    state.allAdvertisement.data = temp;
    state.loading = "fulfilled";
  });
  bulider.addCase(editAd.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // delete ad
  bulider.addCase(deleteAd.pending, (state, action) => {
    state.deleteLoading = "pending";
  });
  bulider.addCase(deleteAd.fulfilled, (state, action) => {
    const temp = [...state.allAdvertisement.data];
    const id = action.payload && action.payload._id;
    temp.forEach((item: any, index) => {
      if (item?._id === id) {
        temp.splice(index, 1);
      }
    });
    state.allAdvertisement.data = temp;
    state.deleteLoading = "fulfilled";
  });
  bulider.addCase(deleteAd.rejected, (state, action) => {
    state.deleteLoading = "rejected";
  });
});

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { IEngageWidget10InitValues } from "../../app/modules/airports/components/settings/SettingsModel";
import getToken from "../../utils/getToken";
import { mydata } from "../../_flyingPaws/partials/widgets";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const CREAT_AD = `${API_URL}/ads/create`;
const GET_ALL_AD = `${API_URL}/ads`;
const ACTIVATE_AD = `${API_URL}/ads/edit`;
const DELETE_AD = `${API_URL}/ads/delete`;

// getall
export const getAllAd = createAsyncThunk(
  "get-all-add",
  async (data: { page: number }) => {
    try {
      const token = getToken();
      let temp = [];
      if (token) {
        const config: AxiosRequestConfig = {
          url: GET_ALL_AD,
          method: "post",
          headers: {
            Authorization: token,
          },
          data: { ...data, limit: 5 },
        };
        const res = await axios(config);
        if (res && res.data.data.ads) {
          for (let i = 0; i < res.data.data.ads.length; i++) {
            temp.push(res.data.data.ads[i]);
          }
        }
        const dineshData = { data: temp, total: res.data.data.total };
        return dineshData;
      }
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// create ad
export const createAd = createAsyncThunk("create-ad", async (data: mydata) => {
  const loadingToastId = toast.loading("Please wait..");
  try {
    const token = getToken();
    const config: AxiosRequestConfig = {
      url: CREAT_AD,
      method: "post",
      headers: {
        Authorization: token,
      },
      data: data,
    };

    const res = await axios(config);
    toast.dismiss(loadingToastId);
    toast.success("Ad created successfully");
    return res.data.data;
  } catch (error: any) {
    toast.dismiss(loadingToastId);
    const { message } = error?.response?.data;
    toast.error(message);
  }
});

// activateAd
export const activateAd = createAsyncThunk(
  "activate-ad",
  async (data: IEngageWidget10InitValues) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ACTIVATE_AD,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      if (res.data.data.active) {
        toast.success("Ad is activated successfully");
      } else {
        toast.success("Ad is deactivated successfully");
      }

      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// edit ad
export const editAd = createAsyncThunk(
  "edit-ad",
  async (data: IEngageWidget10InitValues) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: ACTIVATE_AD,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Edited successfully");
      return res.data.data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// delete ad
export const deleteAd = createAsyncThunk(
  "delete-ad",
  async (data: IEngageWidget10InitValues) => {
    const loadingToastId = toast.loading("Please wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_AD,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: {
          id: data._id,
        },
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

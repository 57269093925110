/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { editAbout } from "../../../../store/addabout/add-about-actions";
import { resetEditAdItem } from "../../../../store/addabout/add-about-reducer";

type Props = {
  className: string;
};

export interface formData {
  id?: string;
  _id?: string;
  description?: string;
  disclaimer?: string;
  url?: string;
  socialMedia?: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
  };
  updatedAt?: Date;
}

const AddAboutForm = ({ className }: Props) => {
  const editAdItem = useAppSelector(
    (state) => state.addAboutReducer.itemToBeEdit
  );
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  // const [facebook, setFacebook] = useState("");
  // const [twitter, setTwitter] = useState("");
  // const [instagram, setInstagram] = useState("");

  // const [linkUrl, setLinkUrl] = useState("");

  const [error, setError] = useState(true);
  // const [isValid, setIsValid] = useState(true);
  // const [isFacebookValid, setIsFacebookValid] = useState(true);
  // const [isInstaValid, setIsInstaValid] = useState(true);
  // const [isTwitterValid, setIsTwitterValid] = useState(true);
  const [scrollTop, setScrollTop] = useState(false);

  // const validateUrl = (value: string) => {
  //   if (!value || value.trim() === "") {
  //     return false; // Empty or whitespace input is considered invalid
  //   }
  //   const urlPattern =
  //     /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w\-.\[\]\~\:\/\?\#\@\!\$\&\'\(\)\*\+\,\;\=\%]*)?$/i;
  //   return urlPattern.test(value);
  // };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editAdItem) {
      setDescription(editAdItem?.about?.description || "");
      setDisclaimer(editAdItem?.about?.disclaimer || "");
      // setLinkUrl(editAdItem?.about?.url || "");
      setId(editAdItem?.about?._id || "");
      // setFacebook(editAdItem?.about?.socialMedia?.facebook || "");
      // setTwitter(editAdItem?.about?.socialMedia?.twitter || "");
      // setInstagram(editAdItem?.about?.socialMedia?.instagram || "");
      setIsEdit(editAdItem?.isEdit || false);
    }
  }, [editAdItem]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEdit) {
      const obj: formData = {
        _id: id,
        description,
        disclaimer,
        // url: linkUrl,
        // socialMedia: {
        //   // facebook,
        //   // twitter,
        //   // instagram,
        // },
      };
      dispatch(editAbout(obj));
      dispatch(resetEditAdItem({ item: {}, isEdit: false }));
    } else {
      toast.info("Fields are only editable.");
    }
    setDescription("");
    setDisclaimer("");
    // setIsValid(true);
    // setIsFacebookValid(true);
    // setIsInstaValid(true);
    // setIsTwitterValid(true);
    // setLinkUrl("");
    // setTwitter("");
    // setInstagram("");
    // setFacebook("");
  };

  const handleReset = () => {
    setDescription("");
    dispatch(resetEditAdItem({ item: {}, isEdit: false }));
  };

  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
      setScrollTop(false);
    }
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <form
      className="form w-100"
      onSubmit={handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >
      <div style={{ backgroundColor: "#000F1E" }} className="card row mb-6">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Add About Us</h3>
          </div>
        </div>
        <div className="col-lg px-9">
          <div className="row">
            <div className="col-lg-6 fv-row">
              <label className=" py-2  fs-6"> Description</label>
              <textarea
                required
                style={{
                  backgroundColor: "#000810",
                  verticalAlign: "top",
                  paddingBottom: "15rem",
                }}
                className="form-control form-control  form-control-solid"
                placeholder="Add about us description..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(true);
                }}
                name="description"
              />
            </div>
            <div className="col-lg-6 fv-row">
              <label className=" py-2  fs-6">Disclaimer</label>
              <textarea
                style={{
                  backgroundColor: "#000810",
                  verticalAlign: "top",
                  paddingBottom: "15rem",
                }}
                className="form-control form-control  form-control-solid"
                placeholder="Add about us disclaimer..."
                value={disclaimer}
                onChange={(e) => {
                  setDisclaimer(e.target.value);
                  setError(true);
                }}
                name="description"
              />
            </div>
            {/* <div className="col-lg-6 fv-row">
              <label className=" py-2 fs-6">Social Media Links</label>
              <InputGroup
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#000810",
                  borderRight: "0.5px solid #2b2b40",
                  borderBottom: "0.5px solid #2b2b40",
                  borderTop: "0.5px solid #2b2b40",
                  borderLeft: "none",
                  borderRadius: "10px",
                  paddingRight: "10px",
                }}
              >
                <input
                  required
                  style={{
                    backgroundColor: "#000810",
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    borderLeft: "0.5px solid #2b2b40",
                  }}
                  className="form-control form-control-lg  me-1 mb-lg-0"
                  placeholder="Enter facebook link..."
                  value={facebook}
                  onChange={(e) => {
                    setFacebook(e.target.value);
                    setError(true);
                    setIsFacebookValid(
                      e.target.value === "" || validateUrl(e.target.value)
                    );
                  }}
                  type="text"
                  name="title"
                />
                <i
                  className="bi bi-facebook fs-4"
                  style={{ color: "#1877F2" }}
                ></i>
              </InputGroup>
              {!isFacebookValid && (
                <p className="text-danger">Invalid url format</p>
              )}

              <InputGroup
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#000810",
                  borderRight: "0.5px solid #2b2b40",
                  borderBottom: "0.5px solid #2b2b40",
                  borderTop: "0.5px solid #2b2b40",
                  borderLeft: "none",
                  borderRadius: "10px",
                  paddingRight: "10px",
                }}
              >
                <input
                  required
                  style={{
                    backgroundColor: "#000810",
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    borderLeft: "0.5px solid #2b2b40",
                  }}
                  className="form-control form-control-lg  me-1 mb-lg-0"
                  placeholder="Enter instagram link..."
                  value={instagram}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                    setError(true);
                    setIsInstaValid(
                      e.target.value === "" || validateUrl(e.target.value)
                    );
                  }}
                  type="text"
                  name="title"
                />
                <i
                  className="bi bi-instagram fs-4"
                  style={{
                    color: "#E4405F",
                  }}
                ></i>
              </InputGroup>
              {!isInstaValid && (
                <p className="text-danger">Invalid url format</p>
              )}
              <InputGroup
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#000810",
                  borderRight: "0.5px solid #2b2b40",
                  borderBottom: "0.5px solid #2b2b40",
                  borderTop: "0.5px solid #2b2b40",
                  borderLeft: "none",
                  borderRadius: "10px",
                  paddingRight: "10px",
                }}
              >
                <input
                  required
                  style={{
                    backgroundColor: "#000810",
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    borderLeft: "0.5px solid #2b2b40",
                  }}
                  className="form-control form-control-lg  me-1 mb-lg-0"
                  placeholder="Enter twitter link..."
                  value={twitter}
                  onChange={(e) => {
                    setTwitter(e.target.value);
                    setError(true);
                    setIsTwitterValid(
                      e.target.value === "" || validateUrl(e.target.value)
                    );
                  }}
                  type="text"
                  name="title"
                />
                <i
                  className="bi bi-twitter fs-4"
                  style={{ color: "#1DA1F2" }}
                ></i>
              </InputGroup>
              {!isTwitterValid && (
                <p className="text-danger">Invalid url format</p>
              )}
              <label className=" py-2 fs-6">Video Link</label>
              <input
                required
                style={{ backgroundColor: "#000810" }}
                className="form-control form-control-lg form-control-solid"
                placeholder="Enter video link"
                value={linkUrl}
                onChange={(e) => {
                  setLinkUrl(e.target.value);
                  setError(true);
                  setIsValid(
                    e.target.value === "" || validateUrl(e.target.value)
                  );
                }}
                type="text"
                name="link"
              />
              {!isValid && <p className="text-danger">Invalid url format</p>}
            </div> */}
          </div>
          <div className="col-lg-6 py-5 fv-row">
            <button
              type="submit"
              style={{ backgroundColor: "#00874F" }}
              className="btn fw-bolder me-2"
            >
              Update About us{" "}
            </button>
            {isEdit && (
              <button
                type="button"
                onClick={handleReset}
                style={{ backgroundColor: "#00874F" }}
                className="btn fw-bolder"
              >
                Reset
              </button>
            )}
            <div className="col-lg-6 py-5 fv-row">
              {!error && (
                <h4 className="text-danger">Make sure to fill all field</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export { AddAboutForm };

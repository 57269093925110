import { createAction, createReducer } from "@reduxjs/toolkit";
import { formData } from "../../app/modules/addabout/components/AddAboutForm";
import { editAbout, getAllAbout } from "./add-about-actions";

export type Iadvertisement = {
  data: formData;
  loading?: string;
  itemToBeEdit: {
    about: formData;
    isEdit?: boolean;
  };
};

const intialState: Iadvertisement = {
  data: {},
  loading: "idle",
  itemToBeEdit: {
    about: {},
    isEdit: false,
  },
};

export const editAboutItem = createAction("edit-about-item", (itemToBeEdit) => {
  return {
    payload: itemToBeEdit,
  };
});

export const resetEditAdItem = createAction(
  "reset-edit-about-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const addAboutReducer = createReducer(intialState, (bulider) => {
  // get all add
  bulider.addCase(getAllAbout.pending, (state, action) => {
    state.data = {};
    state.loading = "pending";
  });
  bulider.addCase(getAllAbout.fulfilled, (state, action) => {
    if (action.payload) {
      state.data = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllAbout.rejected, (state, action) => {
    state.data = {};
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  bulider.addCase(editAboutItem, (state, action) => {
    state.itemToBeEdit = action.payload;
  });
  // reseting the EditState
  bulider.addCase(resetEditAdItem, (state, action) => {
    state.itemToBeEdit = intialState.itemToBeEdit;
  });

  // edit item
  bulider.addCase(editAbout.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(editAbout.fulfilled, (state, action) => {
    
    state.data = action.payload;
    state.loading = "fulfilled";
  });
  bulider.addCase(editAbout.rejected, (state, action) => {
    state.loading = "rejected";
  });
});

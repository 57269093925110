import { createAction, createReducer } from "@reduxjs/toolkit";
import { editOurStory, getAllOurStory } from "./our-story-actions";

type Idata = {
  _id?: string;
  description?: string;
  createdAt?: Date;
  banner?: File;
  updatedAt?: Date;
};

export type IourStory = {
  data: Idata;
  loading?: string;
  itemToBeEdit: {
    ourStory: Idata;
    isEdit?: boolean;
  };
};

const intialState: IourStory = {
  data: {},
  loading: "idle",
  itemToBeEdit: {
    ourStory: {},
    isEdit: false,
  },
};

export const editOurStoryItem = createAction(
  "edit-our-story-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const resetEditOurStoryItem = createAction(
  "reset-edit-our-story-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const ourStoryReducer = createReducer(intialState, (bulider) => {
  // get all add
  bulider.addCase(getAllOurStory.pending, (state, action) => {
    state.data = {};
    state.loading = "pending";
  });
  bulider.addCase(getAllOurStory.fulfilled, (state, action) => {
    if (action.payload) {
      state.data = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllOurStory.rejected, (state, action) => {
    state.data = {};
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  bulider.addCase(editOurStoryItem, (state, action) => {
    state.itemToBeEdit = action.payload;
  });
  // reseting the EditState
  bulider.addCase(resetEditOurStoryItem, (state, action) => {
    state.itemToBeEdit = intialState.itemToBeEdit;
  });

  // edit item
  bulider.addCase(editOurStory.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(editOurStory.fulfilled, (state, action) => {
    state.data = action.payload;
    state.loading = "fulfilled";
  });
  bulider.addCase(editOurStory.rejected, (state, action) => {
    state.loading = "rejected";
  });
});

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { IEngageWidget10InitValues } from "../../app/modules/airports/components/settings/SettingsModel";

import getToken from "../../utils/getToken";
import { mydata } from "../../_flyingPaws/partials/widgets";

// all urls
const API_URL = process.env.REACT_APP_API_URL;
const CREATE_VIDEOS = `${API_URL}/media/create`;
// const CREATE_VIDEOS = `https://8d07-103-238-107-142.ngrok-free.app/api/v1/media/create`;

const GET_ALL_VIDEOS = `${API_URL}/media/videos`;
const DELETE_VIDEOS = `${API_URL}/media/delete`;
const EDIT_MEDIA = `${API_URL}/media/edit`;

// getall video
export const getAllVideo = createAsyncThunk(
  "get-all-videos",
  async (data: { page: number }) => {
    try {
      const token = getToken();
      let temp = [];
      if (token) {
        const config: AxiosRequestConfig = {
          url: GET_ALL_VIDEOS,
          method: "post",
          headers: {
            Authorization: token,
          },
          data: { ...data, limit: 5 },
        };
        const res = await axios(config);
        if (res && res.data.data.videos) {
          for (let i = 0; i < res.data.data.videos.length; i++) {
            const tempDate = new Date(res.data.data.videos[i].createdAt);
            const date = tempDate.getDate().toString().padStart(2, "0");
            const month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
            const year = tempDate.getFullYear();

            temp.push({
              ...res.data.data.videos[i],
              createdAd2: year + "-" + month + "-" + date,
            });
          }
          const dineshData = { data: temp, total: res.data.data.total };
          return dineshData;
        }
      }
    } catch (error: any) {
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

// create video
export const createVideo = createAsyncThunk(
  "create-video",
  async (data: mydata) => {
    const loadingToastId = toast.loading("Plaese wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: CREATE_VIDEOS,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data,
      };
      const res = await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Video created successfully");
      let responseData = [];
      for (let i = 0; i < res.data.data.length; i++) {
        const tempDate = new Date(res.data.data[i].createdAt);
        const date = tempDate.getDate();
        const month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
        const year = tempDate.getFullYear();
        const createdAd2 = year + "-" + month + "-" + date;
        responseData.push({ createdAd2, ...res.data.data[i] });
      }
      return responseData;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);
// delete video
export const deleteVideo = createAsyncThunk(
  "delete-video",
  async (data: IEngageWidget10InitValues) => {
    const loadingToastId = toast.loading("Plaese wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: DELETE_VIDEOS,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: {
          id: data._id,
        },
      };
      await axios(config);
      toast.dismiss(loadingToastId);
      toast.success("Deleted successfully");
      return data;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);
// edit edit video
export const editMedia = createAsyncThunk(
  "edit-video",
  async (data: { currentPage: number; data: IEngageWidget10InitValues }) => {
    const loadingToastId = toast.loading("Plaese wait..");
    try {
      const token = getToken();
      const config: AxiosRequestConfig = {
        url: EDIT_MEDIA,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: data.data,
      };
      const res = await axios(config);

      let responseData = [];
      for (let i = 0; i < res.data.data.length; i++) {
        const tempDate = new Date(res.data.data[i].createdAt);
        const date = tempDate.getDate();
        const month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
        const year = tempDate.getFullYear();
        const createdAd2 = year + "-" + month + "-" + date;
        responseData.push({ createdAd2, ...res.data.data[i] });
      }
      if (res.data.data.status) {
      }
      toast.dismiss(loadingToastId);
      toast.success("Edited Successfully");
      return responseData;
    } catch (error: any) {
      toast.dismiss(loadingToastId);
      const { message } = error?.response?.data;
      toast.error(message);
    }
  }
);

import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { getAllNotification } from "../../../store/send-notification/send-notification-action";
import { SendNotificationTable } from "../../../_flyingPaws/partials/widgets/tables/SendNotificationTable";
import { SendNotifcationForm } from "../../../_flyingPaws/partials/widgets/_new/engage/SendNotifcationForm";

const SendNotifcation: FC = () => {
  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mb-xl-0 ">
        <div className=" col-xxl">
          <SendNotifcationForm className="h-md-100" />
        </div>
      </div>
      <div className="row gy-5 gx-xl-8">
        <div
          style={{ backgroundColor: "#000F1E" }}
          className=" border-0 pt-5 rounded card-header col-xl"
        >
          <SendNotificationTable className="card-xxl-stretch mb-5 mb-xl-5" />
        </div>
      </div>
    </>
  );
};
const SendNotifcationWrapper = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllNotification({ page: 1 }));
  }, [dispatch]);
  return (
    <>
      <SendNotifcation />
    </>
  );
};

export { SendNotifcationWrapper };

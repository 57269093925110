import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import {
  checkIsActive,
  KTIcon,
  toAbsoluteUrl,
  WithChildren,
} from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  onclick?: () => void;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  onclick,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;

  const SidbarIcons: any = () => {
    if (icon && app?.sidebar?.default?.menu?.iconType === "svg") {
      switch (icon) {
        case "advt-svg":
          return (
            <img src={toAbsoluteUrl("/media/logos/advt.svg")} alt="logo" />
          );
        case "videos":
          return (
            <img src={toAbsoluteUrl("/media/logos/video.svg")} alt="logo" />
          );
        case "about-svg":
          return (
            <img src={toAbsoluteUrl("/media/logos/about.svg")} alt="logo" />
          );
        case "notif-svg":
          return (
            <img
              src={toAbsoluteUrl("/media/logos/notification.svg")}
              alt="logo"
            />
          );
        case "logout-svg":
          return (
            <img src={toAbsoluteUrl("/media/logos/logout.svg")} alt="logo" />
          );
        case "ourstory-svg":
          return (
            <img src={toAbsoluteUrl("/media/logos/ourStory.svg")} alt="logo" />
          );
        case "user-svg":
            return (
              <img src={toAbsoluteUrl("/media/logos/user.svg")} alt="logo" />
            );
        default:
          return (
            <span className="menu-icon">
              <KTIcon iconName={icon} className="fs-2" />
            </span>
          );
      }
    } else if (fontIcon && app?.sidebar?.default?.menu?.iconType === "font") {
      return <i className={clsx("bi fs-3", fontIcon)}></i>;
    }
  };

  return (
    <div className="menu-item" style={{ margin: "0.5rem 0" }}>
      <Link
        className={clsx("menu-link without-sub")}
        style={{ backgroundColor: isActive ? "#00874F" : "inherit" }}
        to={to}
        onClick={onclick}
      >
        {hasBullet && (
          // <span className="menu-bullet">
          //   <span
          //     className="bullet  bullet-dot"
          //     style={{ color: "white" }}
          //   ></span>
          // </span>
          <img
            src={toAbsoluteUrl("/media/logos/paws.png")}
            style={{height: "23px", width: "26px"}}
            alt="advertisement"
          />
        )}
        <SidbarIcons />
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        {icon === "about-svg" ? (
          <span
            style={{ color: "white", paddingLeft: "20px", marginLeft: "5px" }}
            className="menu-title fs-6 "
          >
            {title}
          </span>
        ) : (
          <span
            style={{ color: "white", paddingLeft: "10px", marginLeft: "5px" }}
            className="menu-title fs-6 "
          >
            {title}
          </span>
        )}
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };

import { createAction, createReducer } from "@reduxjs/toolkit";
import { IpetReliefArea } from "../../app/modules/petreliefareas/components/AddPetReliefArea";
import {
  airportNameOption,
  AllAirportNamesList,
  allpetReliefArea,
  deletePetReliefArea,
  SendAirportNamesSearch,
} from "./pet-refief-area-action";

type Ipetreliefareas = {
  airportOtpion: {
    label?: string;
    value?: string;
  }[];
  airportOptionsList: {
    label?: string;
    value?: string;
  }[];
  responseFiltered: {
    data: {
      id?: string;
      title?: string;
    }[];
    total: number;
  };
  allPetReliefData: {
    data: {
      id?: string;
      title?: string;
    }[];
    total: number;
  };
  loading: string;
  petItemToBeEdit: {
    item: IpetReliefArea;
    isEdit: boolean;
  };
};

export const petItemToBeEditAction = createAction(
  "pet-edit-item",
  (petItemToBeEdit) => {
    return {
      payload: petItemToBeEdit,
    };
  }
);

export const resetResponseFiltered = createAction(
  "pet-responseFiltered-item",
  (responseFiltered) => {
    return {
      payload: responseFiltered,
    };
  }
);

export const resetEditAdItem = createAction(
  "pet-reset-edit-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);
const initialState: Ipetreliefareas = {
  airportOtpion: [],
  airportOptionsList: [],
  responseFiltered: {
    data: [],
    total: 0,
  },
  allPetReliefData: {
    data: [],
    total: 0,
  },
  loading: "idle",
  petItemToBeEdit: {
    item: {},
    isEdit: false,
  },
};
export const petReliefAreaReducer = createReducer(initialState, (builder) => {
  // airport options name

  builder.addCase(airportNameOption.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(airportNameOption.fulfilled, (state, action) => {
    if (action.payload) {
      state.airportOtpion = action.payload;
    }
    state.loading = "fulfilled";
  });

  builder.addCase(AllAirportNamesList.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(AllAirportNamesList.fulfilled, (state, action) => {
    if (action.payload) {
      state.airportOptionsList = action.payload;
    }
    state.loading = "fulfilled";
  });

  //  all pet rilief area
  builder.addCase(allpetReliefArea.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(allpetReliefArea.fulfilled, (state, action) => {
    if (action.payload) {
      state.allPetReliefData = action.payload;
      state.loading = "fulfilled";
    }
  });

  builder.addCase(SendAirportNamesSearch.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(SendAirportNamesSearch.fulfilled, (state, action) => {
    if (action.payload) {
      state.responseFiltered = action.payload;
      state.loading = "fulfilled";
    }
  });
  builder.addCase(SendAirportNamesSearch.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  builder.addCase(petItemToBeEditAction, (state, action) => {
    console.log({ "#####": action.payload });
    state.petItemToBeEdit = action.payload;
  });
  // reseting the EditState
  builder.addCase(resetEditAdItem, (state, action) => {
    state.petItemToBeEdit = action.payload;
  });

  builder.addCase(resetResponseFiltered, (state, action) => {
    state.responseFiltered = action.payload;
  });
  // delete pet relief area
  builder.addCase(deletePetReliefArea.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(deletePetReliefArea.fulfilled, (state, action) => {
    const id = action.payload?.id;
    const temp = [...state.allPetReliefData.data];
    temp.forEach((item, index) => {
      if (item.id === id) {
        temp.splice(index, 1);
      }
    });
    state.allPetReliefData.data = temp;
    state.loading = "fulfilled";
  });
  builder.addCase(deletePetReliefArea.rejected, (state, action) => {
    state.loading = "rejected";
  });
});

/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editOurStory } from "../../../store/ourstory/our-story-actions";
import { resetEditOurStoryItem } from "../../../store/ourstory/our-story-reducers";

type Props = {
  className: string;
};

export interface IourStoryInt extends FormData {
  _id?: string;
  description?: string;
  createdAt?: Date;
  banner?: File;
  updatedAt?: Date;
}

const OurStoryForm = ({ className }: Props) => {
  const ourStoryEditItem = useAppSelector(
    (state) => state.ourStoryReducer.itemToBeEdit
  );
  const [banner, setBanner] = useState<File | null>(null);
  const [description, setDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [error, setError] = useState(true);
  const [scrollTop, setScrollTop] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ourStoryEditItem) {
      setBanner(ourStoryEditItem.ourStory.banner || null);
      setDescription(ourStoryEditItem?.ourStory?.description || "");
      setId(ourStoryEditItem?.ourStory?._id || "");
      setIsEdit(ourStoryEditItem.isEdit || false);
      setScrollTop(true);
    }
  }, [ourStoryEditItem]);

  console.log({ banner });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEdit) {
      if (banner) {
        const formData: any = new FormData();
        formData.append("_id", id);
        formData.append("banner", banner);
        formData.append("description", description);
        const obj: { id: string; data: IourStoryInt } = { id, data: formData };
        dispatch(editOurStory(obj));
        dispatch(resetEditOurStoryItem({ ourStory: {}, isEdit: false }));
        setDescription("");
        setBanner(null);
      } else {
        setError(false);
      }
      // dispatch(createNotifcation(obj));
      // dispatch(resetEditAdItem({ item: {}, isEdit: false }));
      // dispatch(getAllNotification({ page: 1 }));
    } else {
      toast.info("Fields are only editable.");
    }
  };

  const resetHandler = () => {
    setDescription("");
    setBanner(null);
    dispatch(resetEditOurStoryItem({ ourStory: {}, isEdit: false }));
  };
  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
      setScrollTop(false);
    }
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setError(true);
    if (file) {
      setBanner(file);
    }
  };
  return (
    <form
      className="form w-100"
      onSubmit={handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >
      <div style={{ backgroundColor: "#000F1E" }} className="card row mb-6">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Our Story</h3>
          </div>
        </div>
        <div className="col-lg px-9">
          <div className="row">
            <div className="col-lg-6 py-5 fv-row">
              <label className="pb-5 fs-6">Select Image</label>
              <div
                style={{ backgroundColor: "#000810" }}
                className={`border border-gray-300 text-center border-dashed rounded min-w-125px py-3 px-4 mb-3 d-flex flex-column ${
                  banner ? "" : "align-items-center"
                }`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {!banner && (
                  <label style={{ cursor: "pointer" }}>
                    <>
                      <div>
                        <AiOutlineCloudUpload
                          size={120}
                          className="fs-50 text-gray-400 me-2"
                        />
                        <div className="fw-bold fs-4 py-2 text-gray-400">
                          Browse image
                        </div>
                      </div>
                      <input
                        hidden
                        type="file"
                        onChange={(e) => {
                          const file = e?.target?.files?.[0];
                          setError(true);
                          if (file) {
                            setBanner(file);
                          }
                        }}
                        accept="image/png, image/gif, image/jpeg"
                        name="banner"
                      />
                    </>
                  </label>
                )}
                <div className="d-flex align-items-start justify-content-center position-relative">
                  {banner && (
                    <>
                      <img
                        height={160}
                        width={170}
                        src={
                          typeof banner === "string"
                            ? `${banner}`
                            : URL.createObjectURL(banner)
                        }
                        alt="Selected"
                      />
                      <IoIosCloseCircleOutline
                        size={22}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBanner(null);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 fv-row">
              <label className=" pb-5 py-5 fs-6">Our Story Description</label>
              <textarea
                required
                style={{ backgroundColor: "#000810", height: "180px" }}
                className="form-control form-control form-control"
                placeholder="Enter our story description..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(true);
                }}
                name="description"
              />
            </div>
          </div>

          <div className="col-lg-6 py-5 fv-row">
            <button
              type="submit"
              style={{ backgroundColor: "#00874F" }}
              className="btn fw-bolder px-10"
            >
              Update Our Story
            </button>
            {isEdit && (
              <button
                type="button"
                onClick={resetHandler}
                style={{ backgroundColor: "#00874F" }}
                className="btn fw-bolder px-10 ms-3"
              >
                Reset
              </button>
            )}
            <div className="col-lg-6 py-5 fv-row">
              {!error && (
                <span className="text-danger">Please select image</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export { OurStoryForm };

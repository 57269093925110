import { createAction, createReducer } from "@reduxjs/toolkit";
import { IaddAirportFormData } from "../../app/modules/airports/components/AddAirports";
import {
  allAirports,
  deleteAirport,
  deleteFrequentlySearchedAirport,
  frequentlySearchedAirports,
  getIndividualAirport,
  selectRegionOptions,
} from "./airports-actions";

export type Iairports = {
  selectRegionOption: {
    data?: {
      label?: string;
      value?: string;
    }[];
  };
  allAirportsData: {
    data: {
      id?: string;
      title?: string;
    }[];
    total: number;
  };
  frequentlySearchedAirports: {
    data: {
      id?: string;
      name?: string;
      code?: string;
      count?: number;
      dropDown?: {};
    }[];
    total: number;
  };
  loading: string;
  airportItemToBeEdit: {
    item: IaddAirportFormData;
    isEdit: boolean;
  };
};
const initialState: Iairports = {
  selectRegionOption: {
    data: [],
  },
  allAirportsData: {
    data: [],
    total: 0,
  },
  frequentlySearchedAirports: {
    data: [],
    total: 0,
  },
  loading: "idle",
  airportItemToBeEdit: {
    item: {},
    isEdit: false,
  },
};

// export const airportItemToBeEditAction = createAction(
//   "airport-edit-item",
//   (airportItemToBeEdit) => {
//     return {
//       payload: airportItemToBeEdit,
//     };
//   }
// );

export const resetAirportAddItem = createAction(
  "pet-reset-airport-edit-item",
  (airportItemToBeEdit) => {
    return {
      payload: airportItemToBeEdit,
    };
  }
);

export const airportReducer = createReducer(initialState, (builder) => {
  // Making Item to Readble on other side
  builder.addCase(getIndividualAirport.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(getIndividualAirport.fulfilled, (state, action) => {
    if (action.payload) {
      state.airportItemToBeEdit.item = action.payload.data;
      state.airportItemToBeEdit.isEdit = action.payload.isEdit;
    }
    state.loading = "fulfilled";
  });
  builder.addCase(getIndividualAirport.rejected, (state, action) => {
    state.loading = "rejected";
  });
  // reseting the EditState
  builder.addCase(resetAirportAddItem, (state, action) => {
    state.airportItemToBeEdit = initialState.airportItemToBeEdit;
  });
  // fetching option air select Region
  builder.addCase(selectRegionOptions.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(selectRegionOptions.fulfilled, (state, action) => {
    state.selectRegionOption.data = action.payload;
    state.loading = "fulfilled";
  });
  builder.addCase(allAirports.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(allAirports.fulfilled, (state, action) => {
    if (action.payload) {
      state.allAirportsData = action.payload;
      state.loading = "fulfilled";
    }
  });

  // delete airport
  builder.addCase(deleteAirport.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(deleteAirport.fulfilled, (state, action) => {
    const id = action.payload?.id;
    const temp = [...state.allAirportsData.data];
    temp.forEach((item, index) => {
      if (item.id === id) {
        temp.splice(index, 1);
      }
    });
    state.allAirportsData.data = temp;
    state.loading = "fulfilled";
  });
  builder.addCase(deleteAirport.rejected, (state, action) => {
    state.loading = "rejected";
  });

  /*  Frequently Searched Airports  */
  builder.addCase(frequentlySearchedAirports.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(frequentlySearchedAirports.fulfilled, (state, action) => {
    console.log({"payload": action.payload})
    if (action.payload) {
      state.frequentlySearchedAirports = action.payload;
      state.loading = "fulfilled";
    }
  });

  builder.addCase(deleteFrequentlySearchedAirport.pending, (state, action) => {
    state.loading = "pending";
  });
  builder.addCase(
    deleteFrequentlySearchedAirport.fulfilled,
    (state, action) => {
      const id = action.payload?.id;
      const temp = [...state.frequentlySearchedAirports.data];
      temp.forEach((item, index) => {
        if (item.id === id) {
          temp.splice(index, 1);
        }
      });
      state.frequentlySearchedAirports.data = temp;
      state.loading = "fulfilled";
    }
  );
  builder.addCase(deleteFrequentlySearchedAirport.rejected, (state, action) => {
    state.loading = "rejected";
  });
});

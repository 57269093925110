import axios, { AxiosRequestConfig } from "axios";
import { AuthModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/token`;
export const LOGIN_URL = `${API_URL}/auth/signin`;
// export const LOGIN_URL = ``
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgotPassword`;
export const SET_PASSWORD_URL = `${API_URL}/auth/setPassword/` 

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Create new Password
export function createNewPassword(
  id: string,
  code: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(SET_PASSWORD_URL+id+"/"+code, {
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  // return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
  //   email,
  // });

  const config: AxiosRequestConfig = {
    url: REQUEST_PASSWORD_URL,
    method: "post",
    data: {
      email,
      type: "website",
    },
  };
  return axios(config);
}

export function getUserByToken(token: string) {
  const config: AxiosRequestConfig = {
    url: GET_USER_BY_ACCESSTOKEN_URL,
    method: "post",
    headers: {
      Authorization: token,
    },
    data: {
      token: token,
    },
  };
  return axios(config);
}

import { createAction, createReducer } from "@reduxjs/toolkit";

export const googleMapLatLng = createAction(
  "googlemap-lat-lng",
  (data: { lat: number; lng: number }) => {
    return {
      payload: data,
    };
  }
);

export const resetGoogleMapLatLng = createAction(
  "reset-google-lat-lng",
  (latLng) => {
    return {
      payload: latLng,
    };
  }
);

const initialState = {
  latlng: {
    lat: 0,
    lng: 0,
  },
};

export const latlngReducer = createReducer(initialState, (builder) => {
  builder.addCase(googleMapLatLng, (state, action) => {
    state.latlng = action.payload;
  });
  builder.addCase(resetGoogleMapLatLng, (state, action) => {
    state.latlng = initialState.latlng;
  });
});

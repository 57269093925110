/* eslint-disable jsx-a11y/anchor-is-valid */
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  createVideo,
  editMedia,
  getAllVideo,
  // editVideo,
} from "../../../../../store/videos/add-videos-actions";
import Select from "react-select";
import { OptionType } from "../../../../../app/modules/airports/components/AddAirports";
import {
  editVideoItem,
  resetVideoEditItem,
} from "../../../../../store/videos/add-videos-reducer";

type Props = {
  className: string;
};

interface mydata extends FormData {
  id?: string;
  _id?: string;
  title?: string;
  description?: string;
  link?: string;
  active?: boolean;
  banner?: File;
  redierectLink?: string;
}

const categoryOption = [
  { label: "Hotels", value: "Hotels" },
  { label: "Restaurants", value: "Restaurants" },
  { label: "Hospitals", value: "Hospitals" },
  { label: "Retails", value: "Retails" },
  { label: "Homes", value: "Homes" },
  { label: "About Us", value: "About Us" },
  { label: "Groomers", value: "Groomers" },
  { label: "Videos", value: "Videos" },
];

const typeOption = [
  { label: "Image", value: "Image" },
  { label: "Video", value: "Video" },
];
const AddVideoForm = ({ className }: Props) => {
  const loading = useAppSelector((state) => state.createVideoReducer.loading);
  const editMediaItem = useAppSelector(
    (state) => state.createVideoReducer.itemToBeEdit
  );

  const [title, setTitle] = useState("");
  const [optionType, setOptionType] = useState<OptionType | null>();
  const [optionCategory, setOptionCategory] = useState<OptionType[]>([]);
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState<File | string | null>(null);
  const [redierectLink, setRedirectLink] = useState("");

  const [link, setLink] = useState("");
  const [error, setError] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isVideoUrlValid, setIsVideoUrlValid] = useState(true);
  const [isImageUrlValid, setIsImageUrlValid] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [_id, setId] = useState("");
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    if (editMediaItem) {
      setTitle(editMediaItem?.item?.title || "");
      setDescription(editMediaItem?.item?.description || "");
      setLink(editMediaItem?.item?.webUrl || "");
      setRedirectLink(editMediaItem?.item?.redirectLink || "");
      setIsEdit(editMediaItem?.isEdit || false);
      setPageNumber(editMediaItem?.currentPage || 0);
      setId(editMediaItem?.item?._id || "");
      setScrollTop(true);
      if (editMediaItem.item.webUrl) {
        setBanner(editMediaItem?.item?.webUrl || null);
      }
      if (editMediaItem.item.type) {
        if (
          editMediaItem?.item?.type === "Youtube" ||
          editMediaItem?.item?.type === "Other"
        ) {
          setOptionType({
            label: "Video",
            value: "Video",
          });
        } else {
          setOptionType({
            label: "Image",
            value: "Image",
          });
        }
      }
      if (editMediaItem.item.category) {
        const category = editMediaItem.item.category;
        setOptionCategory([
          {
            label: category,
            value: category,
          },
        ]);
      }
    }
  }, [editMediaItem]);

  const validateUrl = (value: string) => {
    if (!value || value.trim() === "") {
      return false; // Empty or whitespace input is considered invalid
    }
    const urlPattern =
      /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w\-.\[\]\~\:\/\?\#\@\!\$\&\'\(\)\*\+\,\;\=\%]*)?$/i;
    return urlPattern.test(value);
  };

  useEffect(() => {
    if (editMediaItem.isEditDone) {
      dispatch(getAllVideo({ page: pageNumber }));
    }
  }, [editMediaItem.isEditDone, pageNumber]);

  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
      setScrollTop(false);
    }
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEdit) {
      const catOptnValue = optionCategory.map((item) => item.value);
      const formData = new FormData() as mydata;
      formData.append("title", title);
      formData.append("description", description);
      formData.append("webUrl", link);
      formData.append("category", JSON.stringify(catOptnValue));
      formData.append("type", optionType?.value || "");
      formData.append("redirectLink", redierectLink);
      formData.append("mediaId", _id);
      banner && formData.append("image", banner);
      dispatch(editMedia({ currentPage: pageNumber, data: formData }));
      dispatch(
        resetVideoEditItem({
          item: {},
          isEdit: false,
          currentPage: 0,
        })
      );
      setDescription("");
      setLink("");
      setTitle("");
      setBanner(null);
      setId("");
      setOptionCategory([]);
      setOptionType(null);
      setId("");
      setPageNumber(0);
      setScrollTop(true);
      // dispatch(getAllVideo({ page: pageNumber }));
    } else {
      const catOptnValue = optionCategory.map((item) => item.value);
      if (title && description) {
        const formData = new FormData() as mydata;
        formData.append("title", title);
        formData.append("description", description);
        formData.append("webUrl", link);
        formData.append("category", JSON.stringify(catOptnValue));
        formData.append("type", optionType?.value || "");
        formData.append("redirectLink", redierectLink);
        banner && formData.append("image", banner);
        dispatch(createVideo(formData));
        setDescription("");
        setLink("");
        setTitle("");
        setBanner(null);
        setId("");
        setOptionCategory([]);
        setOptionType(null);
        setId("");
        setPageNumber(0);
        setScrollTop(true);
        // dispatch(getAllVideo({ page: 1 }));
      } else {
        setError(false);
      }
    }
  };

  const resetHandler = () => {
    setDescription("");
    setLink("");
    setTitle("");
    setId("");
    setId("");
    setBanner(null);
    setOptionCategory([]);
    setOptionType(null);
    setPageNumber(0);
    dispatch(
      resetVideoEditItem({
        item: {},
        isEdit: false,
        currentPage: 0,
      })
    );
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setError(true);
    if (file) {
      setBanner(file);
    }
  };

  return (
    <form
      className="form w-100"
      onSubmit={handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >
      <div style={{ backgroundColor: "#000F1E" }} className="card row mb-6">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Media</h3>
          </div>
        </div>
        <div className="col-lg px-9">
          <div className="row">
            <div className="col-lg-6 fv-row">
              <label className=" pb-5 fs-6">Media Title</label>
              <input
                required
                style={{ backgroundColor: "#000810", padding: "12px 10px" }}
                className="form-control form-control-lg  mb-3 mb-lg-0"
                placeholder="Enter media title..."
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setError(true);
                }}
                type="text"
                name="title"
              />

              <label className=" pb-5 py-5 fs-6">Media Description</label>
              <textarea
                required
                style={{ backgroundColor: "#000810", height: "100px" }}
                className="form-control form-control form-control"
                placeholder="Enter media description..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(true);
                }}
                name="description"
              />
            </div>

            <div className="col-lg-6 fv-row">
              {optionType?.value === "Image" ? (
                <>
                  <label className="pb-5 fs-6">Select Image</label>
                  <div
                    style={{ backgroundColor: "#000810" }}
                    className={`border border-gray-300 text-center border-dashed rounded min-w-125px py-3 px-4 mb-3 d-flex flex-column`}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    {!banner && (
                      <label style={{ cursor: "pointer" }}>
                        <>
                          <div>
                            <AiOutlineCloudUpload
                              size={50}
                              className="fs-50 text-gray-400 me-2"
                            />
                            <div className="fw-bold fs-6 text-gray-400">
                              Browse image
                            </div>
                          </div>
                          <input
                            hidden
                            type="file"
                            onChange={(e) => {
                              const file = e?.target?.files?.[0];
                              setError(true);
                              if (file) {
                                setBanner(file);
                              }
                            }}
                            accept="image/png, image/gif, image/jpeg"
                            name="banner"
                          />
                        </>
                      </label>
                    )}

                    <div className="d-flex align-items-start justify-content-center position-relative">
                      {banner && (
                        <>
                          <img
                            height={70}
                            width={90}
                            src={
                              typeof banner === "string"
                                ? `${banner}`
                                : URL.createObjectURL(banner)
                            }
                            alt="Selected"
                          />
                          <IoIosCloseCircleOutline
                            size={22}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => setBanner(null)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <label className=" pb-5 fs-6">Media Link</label>
                  <input
                    required
                    style={{ backgroundColor: "#000810", padding: "12px 10px" }}
                    className="form-control form-control-lg form-control"
                    placeholder="Enter media link"
                    value={link}
                    onChange={(e) => {
                      if (optionType?.value === "Image") {
                        setLink("");
                      } else {
                        setLink(e.target.value);
                        setIsVideoUrlValid(
                          e.target.value === "" || validateUrl(e.target.value)
                        );
                      }
                      setError(true);
                    }}
                    type="text"
                    name="link"
                  />
                  {!isVideoUrlValid && (
                    <p className="text-danger">Invalid url format</p>
                  )}
                </>
              )}
              {optionType?.value === "Image" ? (
                <div>
                  <label className=" py-5 fs-6">Redirect Link</label>
                  <input
                    required
                    style={{ backgroundColor: "#000810" }}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Enter redirect link"
                    value={redierectLink}
                    onChange={(e) => {
                      setRedirectLink(e.target.value);
                      setIsImageUrlValid(
                        e.target.value === "" || validateUrl(e.target.value)
                      );
                      setError(true);
                    }}
                    type="text"
                    name="link"
                  />
                  {!isImageUrlValid && (
                    <p className="text-danger">Invalid url format</p>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className=" col-lg fv-row">
                <div className=" row">
                  <div className="col-lg-6 fv-row">
                    <label className=" pb-5 py-5  fs-6">Category</label>
                    <Select
                      options={categoryOption}
                      isMulti
                      value={optionCategory}
                      onChange={(newValue) => {
                        newValue
                          ? setOptionCategory(newValue as OptionType[])
                          : setOptionCategory([]);
                      }}
                      placeholder="Select Media Category"
                      isClearable
                      isSearchable
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required={true}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "5px 0",
                          borderRadius: "10px",
                          color: "white",
                          borderColor: state.isFocused ? "#323248" : "#323248",
                          backgroundColor: "#000810",
                          boxShadow: state.isFocused ? "none" : "none",
                          "&:hover": {
                            borderColor: state.isFocused ? "none" : "none",
                          },
                          outline: "none",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          color: state.isFocused ? "#92929f" : "#92929f",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                          fontFamily: "sans-serif",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#000810"
                            : "inherit",
                          color: state.isFocused ? "white" : "white",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#92929f",
                          fontFamily: "sans-serif",
                          fontSize: "1.1rem",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          color: "white",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          backgroundColor: "#000F1E !important",
                        }),
                        multiValue: (provided, state) => ({
                          ...provided,
                          backgroundColor: "#000F1E",
                          color: "white",
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                      }}
                    />
                  </div>
                  <div className="col-lg-6 fv-row">
                    <label className=" pb-5 py-5  fs-6">Type</label>
                    <Select
                      options={typeOption}
                      value={optionType}
                      onChange={(newValue) => setOptionType(newValue)}
                      placeholder="Select Media Type"
                      isClearable
                      isSearchable
                      required={true}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "5px 0",
                          borderRadius: "10px",
                          color: "white",
                          borderColor: state.isFocused ? "#323248" : "#323248",
                          backgroundColor: "#000810",
                          boxShadow: state.isFocused ? "none" : "none",
                          "&:hover": {
                            borderColor: state.isFocused ? "none" : "none",
                          },
                          outline: "none",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          color: state.isFocused ? "#92929f" : "#92929f",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                          fontFamily: "sans-serif",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#000810"
                            : "inherit",
                          color: state.isFocused ? "white" : "white",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#92929f",
                          fontSize: "1.15rem",
                          fontFamily: "sans-serif",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          color: "white",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          backgroundColor: "#000F1E !important",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-5 fv-row">
            <button
              type="submit"
              style={{ backgroundColor: "#00874F" }}
              className="btn fw-bolder"
            >
              {isEdit ? "Update Media" : "Upload Media"}
            </button>
            {isEdit && (
              <button
                type="button"
                style={{ backgroundColor: "#00874F" }}
                className="btn fw-bolder ms-2"
                onClick={resetHandler}
              >
                Reset
              </button>
            )}
            <div className="col-lg-6 py-5 fv-row">
              {!error && (
                <span className="text-danger">Make sure to fill all field</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export { AddVideoForm };

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllUsers, generateUserCSV } from "../../../store/users/users-actions";
import { toAbsoluteUrl } from "../../../_flyingPaws/helpers";
import { Pagination, styled } from "@mui/material";
import Select, { StylesConfig } from "react-select";
import { SortKey, SortDir } from "../../../store/users/users-actions";

const selectStyles: StylesConfig = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: "5px 0",
    borderRadius: "10px",
    color: "white",
    borderColor: state.isFocused ? "#323248" : "#323248",
    backgroundColor: "#000810",
    marginBottom: "0px",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    outline: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#92929f" : "#92929f",
    fontSize: "1.1rem",
    fontWeight: "500",
    fontFamily: "sans-serif",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#000810" : "inherit",
    color: state.isFocused ? "white" : "white",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#92929f",
    fontFamily: "sans-serif",
    fontSize: "1.1rem",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#000F1E !important",
  }),
}

const StyledPagination = styled(Pagination)(({ theme }) => ({
  float: "right",
  "& .MuiPaginationItem-root": {
    color: "white!important",
    backgroundColor: "#253044!important",
    borderRadius: "8px",
  },
  "& .Mui-selected": {
    backgroundColor: "#00874F!important",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "white!important",
  },
}));

type IincomingData = {
  username?: string,
  email?: string,
  role?: string,
  createdAt?: string
};

interface OptionType {
  label?: string;
  value?: string;
}

const UsersWrapper = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [roleOption, setRoleOption] = useState<OptionType | null>(null);
  const [ffOption, setFFOption] = useState<OptionType | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: SortKey | null, direction: SortDir | null}>({ key: null, direction: null });

  const allUserData = useAppSelector(
    (state) => state.usersReducer.allUserData
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (allUserData.user) {
      setIncomingData(allUserData.user);
    } else {
      setIncomingData([]);
    }
  }, [allUserData.user]);

  useEffect(() => {
    let apiFilterSortObj: { page: number, role?: string, frequentFlyerMembership?: boolean, sort?: SortKey, order?: SortDir } = { page: currentPage };
    if(roleOption) {
      apiFilterSortObj.role = roleOption.value;
    }
    if(ffOption) {
      apiFilterSortObj.frequentFlyerMembership = ffOption.value === 'yes' ? true : false;
    }
    if(sortConfig?.key) {
      apiFilterSortObj.sort = sortConfig.key;
      if(sortConfig?.direction) {
        apiFilterSortObj.order = sortConfig?.direction;
      }
    }

    dispatch(getAllUsers(apiFilterSortObj));
  }, [dispatch, currentPage, roleOption, ffOption, sortConfig]);

  const handleSort = (sortField: SortKey) => {
    let direction = SortDir.Asc;
    if (sortConfig.key === sortField && sortConfig.direction === SortDir.Asc) {
        direction = SortDir.Desc;
    } else if (sortConfig.key === sortField && sortConfig.direction === SortDir.Desc) {
        direction = SortDir.Asc;
    }
    setSortConfig({ key: sortField, direction });
  }

  const [incomingData, setIncomingData] = useState<IincomingData[]>([]);

  return (
    <div
      style={{ backgroundColor: "#000F1E", overflow: "hidden" }}
      className={`card`}
    >
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <div className="card-title flex-column align-items-start">
          <h3>
            <span className="card-label fw-bold fs-3 mb-1">All Users</span>
          </h3>
          <button
            style={{ backgroundColor: "#00874F" }} className="btn mt-4" onClick={() => dispatch(generateUserCSV())}>Export to CSV</button>
        </div>
        <div className="col-lg-4 pb-3 fv-row">
          <Select
            options={[{label: "ADMIN", value: "ADMIN"}, {label: "USER", value: "USER"}]}
            value={roleOption}
            onChange={(newValue) => {
              setRoleOption(newValue as OptionType);
              setCurrentPage(1);
            }}
            placeholder="Select Role"
            isClearable
            isSearchable
            required={true}
            styles={selectStyles}
          />
          <div style={{marginTop: ".5rem"}}>
            <Select
              options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}, {label: "", value: ""}]}
              value={ffOption}
              onChange={(newValue) => {
                setFFOption(newValue as OptionType);
                setCurrentPage(1);
              }}
              placeholder="Select Frequent Flyer Membership"
              isClearable
              isSearchable
              required={true}
              styles={selectStyles}
            />
          </div>
        </div>
      </div>
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive" style={{ height: "60vh" }}>
          {/* begin::Table */}
          <table
            className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
            style={{
              borderCollapse: "inherit",
              borderSpacing: "0 10px",
            }}
          >
            <tbody>
            <tr>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.Name)}
                >
                  Name {sortConfig.key !== SortKey.Name && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.Name && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.Email)}
                >
                  Email {sortConfig.key !== SortKey.Email && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.Email && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.PetName)}
                >
                  Pet Name {sortConfig.key !== SortKey.PetName && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.PetName && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.PetType)}
                >
                  Pet Type {sortConfig.key !== SortKey.PetType && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.PetType && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.PetBreed)}
                >
                  Breed {sortConfig.key !== SortKey.PetBreed && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.PetBreed && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.PetWeight)}
                >
                  Pet Weight {sortConfig.key !== SortKey.PetWeight && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.PetWeight && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                >
                  Carrier Dimensions
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.MostFlownAirline)}
                >
                  Most Flown Airline {sortConfig.key !== SortKey.MostFlownAirline && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.MostFlownAirline && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => handleSort(SortKey.FrequentFlyerMembership)}
                >
                  Frequent Flyer Membership {sortConfig.key !== SortKey.FrequentFlyerMembership && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.FrequentFlyerMembership && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    userSelect: "none"
                  }}
                >
                  Role
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    textAlign: "right",
                    fontWeight: "600",
                    cursor: "pointer",
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                  className="px-5"
                  onClick={() => handleSort(SortKey.CreatedAt)}
                >
                  Member Since {sortConfig.key !== SortKey.CreatedAt && (
                    <i className="fa-solid text-white px-2 fa-sort"></i>
                  )}
                  {sortConfig.key === SortKey.CreatedAt && (
                    <i className={`fa-solid text-white px-2 fa-sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i>
                  )}
                </th>
              </tr>
              {incomingData.length === 0 ? (
                <span>No Data Found</span>
              ) : (
                incomingData?.map((item: any, index: number) => (
                  <>
                    <tr
                      style={{
                        marginBottom: "5px",
                        // backgroundColor: "#000810",
                        borderRadius: "50px",
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderTopLeftRadius: "10px",
                          backgroundColor: "#000810",
                          marginBottom: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <div className="symbol  d-flex symbol-40px ms-2">
                          <img
                            src={toAbsoluteUrl(
                              "/media/logos/creatairporttable.svg"
                            )}
                            className=""
                            alt=""
                          />
                          <div className=" d-flex justify-content-center text-align-center align-items-center">
                            <span className="text-dark ps-5 fw-bold text-hover-primary fs-6">
                              {item.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.email}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.petName}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.petType}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.petBreed}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.petWeight && item.petWeight + ' lbs'}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.carrierDimensions && (item.carrierDimensions.w + '" x ' + item.carrierDimensions.h + '" x ' + item.carrierDimensions.d + '"')}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.mostFlownAirline}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.frequentFlyerMembership === true ? 'Yes' : item.frequentFlyerMembership === false ? 'No' : ''}</span>
                      </td>
                      <td
                        align="left"
                        style={{
                          backgroundColor: "#000810",
                        }}
                      >
                        <span className="">{item.role}</span>
                      </td>
                      <td
                        align="right"
                        style={{
                          backgroundColor: "#000810",
                        }}
                        className="text-end px-5"
                      >
                        <div className="">
                          <span className="">
                            {item.createdAt && new Date(item.createdAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit', 
                              minute: '2-digit' 
                            })}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <div className="card-footer border-0 pt-5">
        <StyledPagination
          count={Math.ceil(allUserData.total / 10)}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export { UsersWrapper };

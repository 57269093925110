import { createAction, createReducer } from "@reduxjs/toolkit";
import { IsendNotificaation } from "../../_flyingPaws/partials/widgets/_new/engage/SendNotifcationForm";
import {
  createNotifcation,
  deleteNotification,
  getAllNotification,
} from "./send-notification-action";

type loadingCondition = "idle" | "pending" | "fulfilled" | "rejected";
export type Iadvertisement = {
  allNotification: {
    data: IsendNotificaation[];
    total: number;
  };
  loading?: string;
  itemToBeEdit: {
    item: IsendNotificaation;
    isEdit?: boolean;
  };
  itemToBeDelete: {
    currentPage: number;
  };
  deleteLoading?: loadingCondition;
};

const intialState: Iadvertisement = {
  allNotification: {
    data: [],
    total: 0,
  },
  loading: "idle",
  itemToBeEdit: {
    item: {},
    isEdit: false,
  },
  itemToBeDelete: {
    currentPage: 0,
  },
  deleteLoading: "idle",
};

export const editNotifcationItem = createAction(
  "edit-send-noti-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const resetEditAdItem = createAction(
  "reset-send-noti-edit-item",
  (itemToBeEdit) => {
    return {
      payload: itemToBeEdit,
    };
  }
);

export const sendNotificationReducer = createReducer(intialState, (bulider) => {
  // create ad
  bulider.addCase(createNotifcation.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(createNotifcation.fulfilled, (state, action) => {
    state.allNotification.data = [
      action.payload,
      ...state.allNotification.data,
    ];
    state.loading = "fulfilled";
  });
  bulider.addCase(createNotifcation.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // get all add
  bulider.addCase(getAllNotification.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(getAllNotification.fulfilled, (state, action) => {
    if (action.payload) {
      state.allNotification = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllNotification.rejected, (state, action) => {
    state.loading = "rejected";
  });

  // Making Item to Readble on other side
  bulider.addCase(editNotifcationItem, (state, action) => {
    state.itemToBeEdit = action.payload;
  });
  // reseting the EditState
  bulider.addCase(resetEditAdItem, (state, action) => {
    state.itemToBeEdit = intialState.itemToBeEdit;
  });

  // delete notification
  bulider.addCase(deleteNotification.pending, (state, action) => {
    state.deleteLoading = "pending";
  });
  bulider.addCase(deleteNotification.fulfilled, (state, action) => {
    const temp = [...state.allNotification.data];
    const id = action.payload && action.payload.data._id;
    temp.forEach((item: any, index) => {
      if (item?._id === id) {
        temp.splice(index, 1);
      }
    });
    state.allNotification.data = temp;
    if (action.payload) {
      state.itemToBeDelete.currentPage = action.payload.currentPage;
    }
    state.deleteLoading = "fulfilled";
  });
  bulider.addCase(deleteNotification.rejected, (state, action) => {
    state.deleteLoading = "rejected";
  });
});

import Modal from "react-bootstrap/Modal";

type Props = {
  show: boolean;
  handleShow: () => void;
  handleClose: () => void;
  handleDelete: () => void;
};

function DeleteDialog({ show, handleShow, handleClose, handleDelete }: Props) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#FFFFFF",
            borderBottom: "none",
          }}
        >
          <Modal.Title
            style={{
              color: "black",
              fontWeight: "400",
              fontSize: "18px",
              margin: "0 auto",
            }}
          >
            Are you sure you want to{" "}
            <span style={{ fontWeight: "800" }}>Delete?</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className="d-flex "
          style={{ backgroundColor: "#FFFFFF", borderTop: "none" }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              color: "black",
              padding: "14px 50px 14px 50px",
              fontSize: "16px",
            }}
            className="btn fw-bolder"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#00874F",
              padding: "14px 50px 14px 50px",
              fontSize: "16px",
            }}
            className="btn fw-bolder"
            onClick={handleDelete}
          >
            Yes, Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteDialog;

import React, { FC } from "react";
import { AddVideoForm } from "../../../_flyingPaws/partials/widgets";
import { AddVideosTable } from "../../../_flyingPaws/partials/widgets/tables/AddVideosTable";

const AddVideos: FC = () => {
  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mb-xl-0 ">
        <div className=" col-xxl">
          <AddVideoForm className="h-md-100" />
        </div>
      </div>
      <div className="row gy-5 gx-xl-8">
        <div
          style={{ backgroundColor: "#000F1E" }}
          className=" border-0 pt-5 rounded card-header col-xl"
        >
          <AddVideosTable className="card-xxl-stretch mb-5 mb-xl-5" />
        </div>
      </div>
    </>
  );
};
const VideosWrapper = () => {
  return (
    <>
      <AddVideos />
    </>
  );
};

export { VideosWrapper };

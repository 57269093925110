import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import {
  checkIsActive,
  KTIcon,
  toAbsoluteUrl,
  WithChildren,
} from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;

  const SidbarIcons: any = () => {
    if (icon && app?.sidebar?.default?.menu?.iconType === "svg") {
      switch (icon) {
        case "paws-svg":
          return (
            <img
              src={toAbsoluteUrl("/media/logos/paws.png")}
              style={{ height: "23px", width: "26px" }}
              alt="advertisement"
            />
          );

        default:
          return (
            <span className="menu-icon">
              <KTIcon iconName={icon} className="fs-2" />
            </span>
          );
      }
    } else if (fontIcon && app?.sidebar?.default?.menu?.iconType === "font") {
      return <i className={clsx("bi fs-3", fontIcon)}></i>;
    }
  };

  return (
    <>
      <div
        className={clsx(
          "menu-item",
          { "here show": isActive },
          "menu-accordion"
        )}
        data-kt-menu-trigger="click"
        style={{
          margin: "0.5rem 0",
          marginRight: "0.5rem",
        }}
      >
        <span className="menu-link">
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}

          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <SidbarIcons />
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span
            style={{ color: "white", paddingLeft: "10px", marginLeft: "5px" }}
            className="menu-title fs-6 "
          >
            {title}
          </span>
          <span className="menu-arrow"></span>
        </span>
        <div
          className={clsx("menu-sub menu-sub-accordion", {
            "menu-active-bg": isActive,
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export { SidebarMenuItemWithSub };

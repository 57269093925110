/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { getAllAd } from "../../../store/advertisement/advertisement-action";
import {
  TablesWidget10,
  EngageWidget10,
} from "../../../_flyingPaws/partials/widgets";
import { useAuth } from "../../modules/auth";

const Advertisement: FC = () => (
  <>
    <div className="row g-5 g-xl-5 mb-5 mb-xl-0 ">
      <div className=" col-xxl">
        <EngageWidget10 className="h-md-100" />
      </div>
    </div>

    <div className="row gy-5 gx-xl-8">
      <div
        style={{ backgroundColor: "#000F1E" }}
        className=" border-0 pt-5 rounded card-header col-xl"
      >
        <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-5" />
      </div>
    </div>
  </>
);

const AdvertisementWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
      dispatch(getAllAd({ page: 1 }));
    }
  }, [currentUser, dispatch]);
  return (
    <>
      <Advertisement />
    </>
  );
};

export { AdvertisementWrapper };

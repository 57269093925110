import { createReducer } from "@reduxjs/toolkit";
import { getAllUsers, generateUserCSV } from "./users-actions";

type Idata = {
  _id?: string;
  description?: string;
  createdAt?: Date;
  banner?: File;
  updatedAt?: Date;
};

export type Iusers = {
  data: Idata;
  loading?: string;
  allUserData: {
    user: {
      username?: string;
      email?: string;
      role?: string;
      createdAt?: string;
    }[];
    total: number;
  };
};

const intialState: Iusers = {
  data: {},
  loading: "idle",
  allUserData: {
    user: [],
    total: 0
  }
};

export const usersReducer = createReducer(intialState, (bulider) => {
  bulider.addCase(getAllUsers.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(getAllUsers.fulfilled, (state, action) => {
    if (action.payload) {
      state.allUserData = action.payload;
    }
    state.loading = "fulfilled";
  });
  bulider.addCase(getAllUsers.rejected, (state, action) => {
    state.data = {};
    state.loading = "rejected";
  });

  bulider.addCase(generateUserCSV.pending, (state, action) => {
    state.loading = "pending";
  });
  bulider.addCase(generateUserCSV.fulfilled, (state, action) => {
    state.loading = "fulfilled";
  });
  bulider.addCase(generateUserCSV.rejected, (state, action) => {
    state.loading = "rejected";
  });
});
